import React from 'react'
import { Helmet } from 'react-helmet'; 

import CodeBlock from '../../components/CodeBlock'
import Title from '../../components/Title'
import Paragraph from '../../components/Paragraph'
import SubTitle from '../../components/SubTitle'
import ImageBlock from '../../components/ImageBlock'
import DownSpace from '../../components/DownSpace'
import ColabButton from '../../components/ColabButton'
import Banner from '../../components/Banner'

import EquationRenderer from '../../components/EquationRenderer';

const imports = `!pip install opendatasets kornia --upgrade --quiet
import torch, torchvision, tqdm, matplotlib.pyplot, opendatasets, math, numpy, PIL, json, kornia, os, imageio`

const focals = `def get_focal_length(width, camera_angle_x):
    return 0.5 * width / numpy.tan(0.5 * camera_angle_x)`

const raydiresc = `def get_ray_directions(H, W, focal):
    grid = kornia.create_meshgrid(H, W, normalized_coordinates=False)[0]
    i, j = grid.unbind(-1)

    directions = torch.stack([(i-W/2)/focal, -(j-H/2)/focal, -torch.ones_like(i)], -1)

    return directions`

const ray2dir = `def get_rays(directions, c2w):
    rays_d = directions @ c2w[:, :3].T
    rays_d = rays_d / torch.norm(rays_d, dim=-1, keepdim=True)

    rays_o = c2w[:, 3].expand(rays_d.shape)

    rays_d = rays_d.view(-1, 3)
    rays_o = rays_o.view(-1, 3)

    return rays_o, rays_d`

const computeac = `def compute_accumulated_transmittance(alphas):
    accumulated_transmittance = torch.cumprod(alphas, 1)
    return torch.cat((torch.ones((accumulated_transmittance.shape[0], 1), device=alphas.device),
                  accumulated_transmittance[:, :-1]), dim=-1)`

const render_rays = `def render_rays(nerf_model, ray_origins, ray_directions, hn=0, hf=0.5, nb_bins=192):
    device = ray_origins.device
    t = torch.linspace(hn, hf, nb_bins, device=device).expand(ray_origins.shape[0], nb_bins)

    mid = (t[:, :-1] + t[:, 1:]) / 2.
    lower = torch.cat((t[:, :1], mid), -1)
    upper = torch.cat((mid, t[:, -1:]), -1)
    u = torch.rand(t.shape, device=device)
    t = torch.add(lower, torch.mul(torch.sub(upper, lower), u))

    delta = torch.cat((torch.sub(t[:, 1:], t[:, :-1]), torch.full((ray_origins.shape[0], 1), 1e10, device=device)), -1)

    x = torch.add(ray_origins.unsqueeze(1), torch.mul(t.unsqueeze(2), ray_directions.unsqueeze(1)))
    ray_directions = ray_directions.expand(nb_bins, ray_directions.shape[0], 3).transpose(0, 1)

    colors, sigma = nerf_model(x.reshape(-1, 3), ray_directions.reshape(-1, 3))
    colors = colors.reshape(x.shape)
    sigma = sigma.reshape(x.shape[:-1])

    alpha = torch.sub(1.0, torch.exp(torch.neg(torch.mul(sigma, delta))))
    accumulated_transmittance = compute_accumulated_transmittance(torch.sub(1.0, alpha))
    weights = torch.mul(accumulated_transmittance.unsqueeze(2), alpha.unsqueeze(2))
    c = torch.sum(torch.mul(weights, colors), dim=1)
    weight_sum = torch.sum(torch.sum(weights, dim=-1), dim=-1)

    return torch.add(torch.add(c, 1.0), torch.neg(weight_sum.unsqueeze(-1)))`

const dataset = `class NeRFDataset(torch.utils.data.Dataset):
    def __init__(self, meta, frame_size=(128, 128)):
        self.meta = meta

        self.frame_size = frame_size

        # Define the transforms that will be applied to each frame.
        self.frame_transforms = torchvision.transforms.Compose([
            torchvision.transforms.Resize(self.frame_size),
            torchvision.transforms.ToTensor()
        ])

        self.h, self.w = self.frame_size[0], self.frame_size[1]

        # self.near is set to 2.0, indicating that objects closer than 2.0 units to the camera will be clipped (not rendered).
        # Similarly, self.far is set to 6.0, meaning that objects farther away than 6.0 units from the camera will also be clipped.
        self.near, self.far = 2, 6

        self.bounds = numpy.array([self.near, self.far])

        self.focal_length = get_focal_length(self.w, meta['camera_angle_x'])

        self.directions = get_ray_directions(self.h, self.w, self.focal_length)

        self.all_ray_origins, self.all_ray_directions, self.all_rgbs = self.return_samples()

        self.all_ray_origins, self.all_ray_directions, self.all_rgbs = self.all_ray_origins.view(-1, 3), self.all_ray_directions.view(-1, 3), self.all_rgbs.view(-1, 3)

    # Retrieved from (https://github.com/kwea123/nerf_pl/blob/master/datasets/blender.py)
    def return_samples(self):
        image_paths, poses = [], []
        all_rgbs, all_ray_origins, all_ray_directions = [], [], []

        for frame in self.meta['frames']:
            pose = numpy.array(frame['transform_matrix'])[:3, :4]
            poses += [pose]

            c2w = torch.FloatTensor(pose)

            image_path = os.path.join(root_dir, f"{frame['file_path'][2:]}.png")
            image_paths += [image_path]

            img = PIL.Image.open(image_path)
            img = img.resize(self.frame_size, PIL.Image.LANCZOS)
            img = self.frame_transforms(img)

            img = img.view(4, -1).permute(1, 0)

            img_rgb = img[:, :3] * img[:, -1:] + (1 - img[:, -1:])
            max_alpha = torch.max(img[:, -1:])
            img_rgb /= max_alpha

            all_rgbs += [img_rgb.unsqueeze(0)]

            rays_o, rays_d = get_rays(self.directions, c2w)

            all_ray_origins += [rays_o.unsqueeze(0)]
            all_ray_directions += [rays_d.unsqueeze(0)]

        all_ray_origins = torch.cat(all_ray_origins, 0)
        all_ray_directions = torch.cat(all_ray_directions, 0)
        all_rgbs = torch.cat(all_rgbs, 0)

        return all_ray_origins, all_ray_directions, all_rgbs

    def __len__(self):
        return len(self.all_ray_origins)

    def __getitem__(self, idx):

        sample = {
            'ray_origins': self.all_ray_origins[idx],
            'ray_directions': self.all_ray_directions[idx],
            'rgbs': self.all_rgbs[idx]
        }

        return sample`

const readjson = `root_dir = "./nerf-dataset/nerf_synthetic/nerf_synthetic/ship/"

with open(root_dir + "transforms_train.json", 'r') as file:
    training_meta = json.load(file)

with open(root_dir + "transforms_val.json", 'r') as file:
    validation_meta = json.load(file)`

const sets = `validation_dataset = NeRFDataset(validation_meta, frame_size=(128, 128))

training_dataset   = NeRFDataset(training_meta,   frame_size=(128, 128))`

const encod = `def positional_encoding(x, L):
    out = [x]
    for j in range(L):
        out.append(torch.sin(2 ** j * x))
        out.append(torch.cos(2 ** j * x))
    return torch.cat(out, dim=1)`

const model = `class NeRF(torch.nn.Module):
    def __init__(self, embedding_dim_pos=16, embedding_dim_direction=8, hidden_dim=128):
        super(NeRF, self).__init__()

        # Positional encoding block
        self.block1 = torch.nn.Sequential(
            torch.nn.Linear(embedding_dim_pos * 6 + 3, hidden_dim),
            torch.nn.ReLU(),
            torch.nn.Linear(hidden_dim, hidden_dim),
            torch.nn.ReLU(),
            torch.nn.Linear(hidden_dim, hidden_dim),
            torch.nn.ReLU(),
            torch.nn.Linear(hidden_dim, hidden_dim),
            torch.nn.ReLU()
        )

        # Density estimation block
        self.block2 = torch.nn.Sequential(
            torch.nn.Linear(embedding_dim_pos * 6 + hidden_dim + 3, hidden_dim),
            torch.nn.ReLU(),
            torch.nn.Linear(hidden_dim, hidden_dim),
            torch.nn.ReLU(),
            torch.nn.Linear(hidden_dim, hidden_dim),
            torch.nn.ReLU(),
            torch.nn.Linear(hidden_dim, hidden_dim + 1)
        )

        # Color estimation blocks
        self.block3 = torch.nn.Sequential(
            torch.nn.Linear(embedding_dim_direction * 6 + hidden_dim + 3, hidden_dim // 2),
            torch.nn.ReLU()
        )

        self.block4 = torch.nn.Sequential(
            torch.nn.Linear(hidden_dim // 2, 3),
            torch.nn.Sigmoid()
        )

        self.embedding_dim_pos = embedding_dim_pos
        self.embedding_dim_direction = embedding_dim_direction
        self.relu = torch.nn.ReLU()

    def forward(self, o, d):
        # Create embeddings
        emb_x = positional_encoding(o, self.embedding_dim_pos)
        emb_d = positional_encoding(d, self.embedding_dim_direction)

        # Positional encoding block
        h = self.block1(emb_x)

        # Density estimation block
        tmp = self.block2(torch.cat((h, emb_x), dim=1))
        h, sigma = tmp[:, :-1], self.relu(tmp[:, -1])

        # Color estimation block
        h = self.block3(torch.cat((h, emb_d), dim=1))
        c = self.block4(h)

        return c, sigma  # Output color, density`

const training = `def train(nerf_model, optimizer, scheduler, data_loader, device='cpu', hn=2, hf=6, nb_epochs=100000, nb_bins=192, H=128, W=128):
    training_loss = []

    for epoch in range(int(nb_epochs)):
        tqdm_data_loader = tqdm.tqdm(data_loader, desc=f'Epoch {epoch + 1}/{nb_epochs}', position=0, leave=True)

        for batch_idx, batch in enumerate(tqdm_data_loader):
            ray_origins = batch['ray_origins'][:, :3].to(device)
            ray_directions = batch['ray_directions'][:, :3].to(device)
            ground_truth_px_values = batch['rgbs'][:, :3].to(device)

            regenerated_px_values = render_rays(nerf_model, ray_origins, ray_directions, hn=hn, hf=hf, nb_bins=nb_bins)
            loss = ((ground_truth_px_values - regenerated_px_values) ** 2).sum()

            optimizer.zero_grad()
            loss.backward()
            optimizer.step()

        training_loss.append(loss.item())

        scheduler.step()

    return training_loss`

const vali = `@torch.no_grad()
def test(hn, hf, model, dataset, chunk_size=10, nb_bins=192, H=128, W=128, device='cuda'):
    all_generated_images = []

    frame_folder = './generated_frames/'

    os.makedirs(frame_folder, exist_ok=True)

    for img_index in range(len(dataset.meta['frames'])):
        sample = dataset[img_index * H * W: (img_index + 1) * H * W]

        ray_origins = sample['ray_origins'][:, :3].to(device)
        ray_directions = sample['ray_directions'][:, :3].to(device)

        data = []
        for i in range(int(numpy.ceil(H / chunk_size))):
            ray_origins_ = ray_origins[i * W * chunk_size: (i + 1) * W * chunk_size]
            ray_directions_ = ray_directions[i * W * chunk_size: (i + 1) * W * chunk_size]

            regenerated_px_values = render_rays(model, ray_origins_, ray_directions_, hn=hn, hf=hf, nb_bins=nb_bins)
            data.append(regenerated_px_values)

        img = torch.cat(data).data.cpu().numpy().reshape(H, W, 3)
        img_uint8 = (img * 255).astype(numpy.uint8)
        all_generated_images.append(img_uint8)

        # Save individual frame
        frame_path = os.path.join(frame_folder, f'frame_{img_index:03d}.png')
        imageio.imwrite(frame_path, img_uint8)

    # Create and save the gif using imageio
    gif_path = './generated_images.gif'
    imageio.mimsave(gif_path, all_generated_images, duration=500)`

const sch = `model_optimizer = torch.optim.Adam(model.parameters(), lr=1e-3)

scheduler = torch.optim.lr_scheduler.MultiStepLR(model_optimizer, milestones=[4, 8, 12], gamma=0.5)`

const pre = `!pip install -U --no-cache-dir gdown --pre
  
!gdown --no-cookies 1IAwsk7L3QZitPUbnWLFp6bUAtsuCaaBH`

const NeRFFromScratch = () => {
  return (
    <div className='custom-font pt-9'>
        <Helmet>
          <title>Building a NeRF Technique for Synthetic Scene Reconstruction from Scratch Using Pytorch</title>
          <meta name="author" content="Dinis Martinho" />
          <meta name="description" content="In this article, I will guide you through the step-by-step process of implementing a complete NeRF (Neural Radiance Fields) from scratch using PyTorch. NeRFs are revolutionary techniques for scene representation that leverage neural networks, offering unique advantages in rendering and view synthesis." />
        </Helmet>

        <Title Title="Building a NeRF Technique for Synthetic Scene Reconstruction from Scratch Using Pytorch" date='13 Feb 2024' />
        <br />
        <ColabButton notebookUrl="https://colab.research.google.com/drive/17CH2r_I42FX3dV_UAZ60XJYoAwa0drNl" paperURL="https://arxiv.org/abs/2003.08934" />
        <br />

        <SubTitle Title="Introduction" noMarginTop={true} />
        <br />
        <Paragraph text="In this article, I will guide you through the step-by-step process of implementing a complete NeRF (Neural Radiance Fields) from scratch using PyTorch. NeRFs are revolutionary techniques for scene representation that 
        leverage neural networks, offering unique advantages in rendering and view synthesis." />
        <br />
        <div className='flex content-center justify-center'>
            <img className='w-[192px] h-[192px]' src={process.env.PUBLIC_URL + "/Building-a-NeRF-Technique-for-Synthetic-Scene-Reconstruction-from-Scratch-Using-Pytorch-I/gif-blog.gif"} />
        </div>
        <br />
        <Paragraph text="I first learned about NeRFs when researching the methods used by Meta, formerly known as Facebook, to translate videos and images from real-life scenery 
        into 3D scenes. After learning that these techniques were actually powered by very small neural networks and could be trained without requiring massive computational resources, 
        I decided to implement my own." />
        <br />
        <Paragraph text="I decided to implement the paper 'NeRF: Representing Scenes as Neural Radiance Fields for View Synthesis', authored by Ben Mildenhall, Pratul P. Srinivasan, 
        Matthew Tancik, Jonathan T. Barron, Ravi Ramamoorthi, and Ren Ng, which was presented at CVPR 2020. This paper introduced a groundbreaking method for 
        representing 3D scenes using a neural network to model a continuous 5D function that outputs color and density at any spatial location and 
        viewing direction. This approach enabled high-fidelity view synthesis through differentiable volumetric rendering, allowing the generation of detailed and 
        realistic novel views of complex scenes. NeRF's innovation has profoundly impacted the fields of computer vision and graphics, sparking extensive 
        subsequent research." />
        <br />
        <Paragraph text="To learn about the concept of NeRFs, I utilized several excellent articles, videos, and implementations. In particular, I would like to reference the YouTube video 'NeRF: Representing Scenes as Neural Radiance Fields for View Synthesis (ML Research Paper Explained)' from the YouTube channel 'Yannic Kilcher,' and the YouTube video 'Neural Radiance Fields | NeRF in 100 lines of PyTorch code' from the 'Papers in 100 Lines of Code' YouTube channel. Much of the code in my implementation comes from the former video, although I included certain steps that were missed in his implementation, especially in the data processing, which I found to be significantly unique and potentially confusing. For a comprehensive list of all the resources used, please refer to the references section below." />
        <br />

        <SubTitle Title="Understanding NeRFs from a practical point of view" />
        <br />
        <Paragraph text="In the realm of 3D rendering, traditional methods rely on predefined shapes and complex calculations to construct virtual scenes. These 
        techniques employ geometric primitives and intricate algorithms to create the appearance of depth, light, and texture." />
        <br />
        <Paragraph text="Neural Radiance Fields (NeRFs) represent a significant departure from conventional approaches. Instead of using predefined shapes, NeRFs analyze data on the positions and directions of rays obtained from pre-rendered scenes or real-life photos to synthesize new views from novel viewpoints and directions. This approach allows NeRFs to capture complex environments from image and video data and translate them into detailed 3D scenes with high fidelity." />
        <br />
        <Paragraph text="One unique trait of NeRFs is their highly efficient memory management. They can compress complex 3D scenes into compact neural networks, requiring less storage space while still delivering stunning visual results." />
        <br />

        <Paragraph text="This breakthrough in rendering technology isn't just about improving memory efficiency; it's about unlocking new potential in computer graphics and visualization. NeRFs are expanding possibilities — from enhancing video quality to creating immersive 3D experiences — by pushing the boundaries of digital imagery." />
        <br />

        <Paragraph text="Moreover, this advancement opens doors to transformative applications. By harnessing neural networks to generate 3D spaces from coordinates and images, combined with modern geometry processing techniques, we can seamlessly translate virtual environments into tangible geometric representations. This integration holds promise across diverse fields, including immersive virtual experiences and augmented reality applications, fostering innovation and advancing digital visualization." />
        <br />

        <SubTitle Title="Importing all the necessary libraries" />
        <br />
        <CodeBlock code={imports} />
        <br />

        <SubTitle Title="Downloading the raw data from Kaggle" />
        <br />
        <Paragraph text="For this project, I've chosen to train my NeRF using synthetic data sourced from the 'NeRF Dataset' available on Kaggle. You can access the dataset using the `opendatasets` library, which requires a Kaggle account for using their download API. The dataset contains different subsets, and for this project, I'm focusing specifically on the subset that deals with ship images." />
        <br />
        <Paragraph text="You can potentially use another dataset from Kaggle if you wish, but much of the code in this implementation has been hardcoded to work exclusively with the structure of this particular dataset." />
        <br />
        <CodeBlock code='opendatasets.download("https://www.kaggle.com/datasets/sauravmaheshkar/nerf-dataset")' />
        <br />

        <SubTitle Title="Understanding the dataset" />
        <br />
        <Paragraph text="I have opted to utilize the 'ship' subset of data. This subset is structured across three folders, each 
        containing images of frames captured from various angles within a 3D scene, alongside three corresponding JSON files providing metadata for 
        each image. Within these JSON files, crucial information such as the `camera_angle_x` is available, enabling us to derive the focal length of the camera. 
        Additionally, the `transform_matrix` is also provided, which we will use to compute both camera rotation and position within the 3D space." />
        <br />
        
        <SubTitle Title="Implementing the necessary utility functions" />
        <br />
        <Paragraph text="We will need to implement numerous mathematical concepts commonly used in rendering engines. All of the following functions have been 
        imported from either the 'nerf_pl' repository or the implementation provided by the 'Papers in 100 Lines of Code' YouTube channel. Both of these references 
        can be found in the references section of this article. I will try to explain what each concept does from a high-level perspective." />
        <br />
        <Paragraph text="The function `get_focal_length` will be used to determine the camera's focal length based on its `camera_angle_x` and pixel width. This 
        is useful for us to calculate accurate pixel directions and positions within the scene." />
        <br />
        <CodeBlock code={focals} />
        <br />
        <Paragraph text="The `get_ray_directions` function
        calculates, for each pixel in any of our image frames, its direction relative to the camera within 3D space. This computation is based on the given 
        camera parameters, including height `(H)`, width `(W)`, and focal length `(focal)`." />
        <br />
        <CodeBlock code={raydiresc} />
        <br />
        <Paragraph text="The `get_rays` function is 
        designed to generate ray origins `(rays_o)` and normalized directions `(rays_d)` in world coordinates for all pixels in a given image. It takes 
        precomputed ray directions in camera coordinates `(directions)` and a transformation matrix `(c2w)` representing the camera-to-world transformation 
        as inputs. The resulting outputs, `rays_o` and `rays_d`, are flattened into a shape of `(H*W, 3)` for convenient utilization in subsequent 
        calculations or computations." />
        <br />
        <CodeBlock code={ray2dir} />
        <br />
        <Paragraph text="The `compute_accumulated_transmittance` function calculates the accumulated transmittance along a ray in a 
        scene. It takes a tensor `alphas` representing the transmittance at each sampling point along the ray and computes the cumulative product of these 
        transmittance values. The resulting tensor represents the accumulated transmittance at each sampling point. This is crucial for rendering realistic 
        images, as it accounts for the attenuation of light along the ray through the scene." />
        <br />
        <CodeBlock code={computeac} />
        <br />
        <Paragraph text="The `render_rays` function will be utilized to render new views of our scene. It takes as input ray origins, and directions. It samples 
        points along each ray, perturbs the sampling, and computes the accumulated transmittance and pixel values using the NeRF model. The function ensures 
        that sampled points are weighted according to their transmittance, resulting in more accurate color representation. The final image is obtained by 
        summing up the weighted colors along the rays, considering the accumulated transmittance." />
        <br />
        <CodeBlock code={render_rays} />
        <br />
        <Paragraph text="In the `render_rays` function, the `nb_bins` parameter represents the number of samples or steps taken along each ray for 
        rendering. It is used to discretize the volume along each ray into a set of intervals or bins. The purpose of using multiple bins is to approximate 
        the integral along the ray path, allowing for more accurate rendering of scenes, especially in the presence of complex lighting and shading 
        effects." />
        <br />

        <SubTitle Title="Creating our custom dataset and dataloader components" />
        <br />
        <Paragraph text="We need to develop a dataset component capable of retrieving our raw data and transforming it appropriately. This transformation 
        should enable our future NeRF model to learn its patterns. Currently, we have a folder containing images from various angles and positions, and 
        metadata about the camera's position and direction. For each pixel in each frame, we must output the ray's position and direction, along with its 
        actual RGB values." />
        <br />
        <Paragraph text="I've chosen to start by defining some essential variables, including the desired frame size, its transforms, the near and far 
        plane distances of the camera, and the focal length. Additionally, I'll determine the relative direction of each pixel in an image using the 
        previously built function `get_ray_directions`." />
        <br />
        <CodeBlock code={dataset} />
        <br />
        <Paragraph text="This dataset component was modified from the 'blender.py' file on the 'nerf_pl' repository on GitHub. A reference to it can be found in the references 
        section of this post. Some functions were modified to ensure proper functionality with our dataset." />
        <br />
        <CodeBlock code={readjson} />
        <br />
        <CodeBlock code={sets} />
        <br />
        <CodeBlock code="training_loader   = torch.utils.data.DataLoader(training_dataset,   batch_size=2048, shuffle=True)" />
        <br />

        <SubTitle Title="Designing the simple MLP NeRF architecture" />
        <br />
        <Paragraph text="The model described in the paper should be a simple MLP that takes directions and positions as input and outputs densities and color values. " />
        <Paragraph text=" 
        The 'Papers in 100 Lines of Code' YouTube channel created and explained this 
        implementation thoroughly in their video. This model essentially consists of two heads: one that outputs a density value and another that 
        outputs the three color pixel values. The encodings for the position and direction vectors are constructed using a common sine-cosine encoding function." />
        <br />
        <CodeBlock code={encod} />
        <br />
        <CodeBlock code={model} />
        <br />

        <SubTitle Title="Implementing the training function" />
        <br />
        <Paragraph text="The training function for our NeRF is surprinsingly simple. All we need is to load some batches of data, containing the pixels 
        positions, directions and real rgb values, send the positions and directions through our model, and compare the outputted color and real color 
        values as our loss." />
        <Paragraph text="The chosen loss for this task is a simple Mean Squared Error Loss (MSE Loss), which prioritizes minimizing the squared differences 
        between predicted and actual values. This helps in penalizing deviations and encourages the model to converge towards more accurate predictions." />
        <br />
        <CodeBlock code={training} />
        <br />

        <SubTitle Title="Implementing the validation function" />
        <br />
        <Paragraph text="We will create a validation function that iterates through the validation dataset, sequentially retrieving `W*H` values from it, 
        which include both the positions, directions, and real RGB values. We make our model predict the corresponding fake RGB values and save them. This 
        process is repeated for each image in the validation set. Finally, we generate a gif to smoothly visualize the results." />
        <br />
        <CodeBlock code={vali} />
        <br />

        <SubTitle Title="Training and validating the NeRF model" />
        <br />
        <Paragraph text="Now, all we need to do is train the model, ensuring that all the correct parameters are passed in, and then validate it on the 
        validation dataset. Inferring this type of NeRF can take up to several minutes." />
        <br />
        <CodeBlock code='device = "cuda"' />
        <br />
        <CodeBlock code="model = NeRF(hidden_dim=256).to(device)" />
        <br />
        <CodeBlock code={sch} />
        <br />
        <CodeBlock code="train(model, model_optimizer, scheduler, training_loader, device=device, hn=2, hf=6, nb_epochs=1000, nb_bins=192, H=128, W=128)" />
        <br />
        <CodeBlock code="test(hn=2, hf=6, model=model, dataset=validation_dataset, chunk_size=10, nb_bins=192, H=128, W=128, device=device)" />
        <br />
        <div className='flex content-center justify-center'>
            <img className='w-[192px] h-[192px]' src={process.env.PUBLIC_URL + "/Building-a-NeRF-Technique-for-Synthetic-Scene-Reconstruction-from-Scratch-Using-Pytorch-I/gif-blog.gif"} />
        </div>
        <br />
        <Paragraph text="If you prefer not to train your own model due to the lengthy training times and possibly demanding computational resources, you can 
        download my weights by executing the following cells. " />
        <br />  
        <CodeBlock code={pre} />
        <br />  
        <CodeBlock code="model.load_state_dict(torch.load('model.pth'))" />
        <br />

        <SubTitle Title="Summative insights and future considerations" />
        <br />
        <Paragraph text="In conclusion, you are now capable of implementing a NeRF technique, capable of achieving satisfactory results in scene reconstruction. However, 
        it's important to acknowledge that this technique is quite old, and many more efficient and performant techniques have emerged since then." />
        <Paragraph text="Future work could involve the utilization of superior and larger models, along with exploring different techniques, perhaps incorporating voxels or temporary memory caches to expedite both training and inference processes." />
        <br />

        <SubTitle Title="Resources used" />
        <br />
        <Paragraph text="▸ [1] Mildenhall, B., Srinivasan, P. P., Tancik, M., Barron, J. T., Ramamoorthi, R., & Ng, R. (2020). NeRF: Representing Scenes as Neural Radiance Fields for View Synthesis. arXiv preprint `arXiv:2003.08934.`" />
        <br />
        <Paragraph text="▸ [2] Kaggle. (n.d.). NeRF Dataset. Kaggle. `https://www.kaggle.com/ datasets / sauravmaheshkar / nerf-dataset`" />
        <br />
        <Paragraph text="▸ [3] Papers in 100 Lines of Code. (2023, January 8). Neural Radiance Fields | NeRF in 100 lines of PyTorch code [Video]. YouTube. `https://youtu.be/Q1zqf5tfeJw?si=4TwckTEJrMzdrx_i`" />
        <br />
        <Paragraph text="▸ [4] Kilcher, Y. (2021, April 19). NeRF: Representing Scenes as Neural Radiance Fields for View Synthesis (ML Research Paper Explained) [Video]. YouTube. `https://youtu.be/CRlN-cYFxTk?si=FmU0s1YEzwqgV8Q3`" />
        <br />
        <Paragraph text="▸ [5] Kwea, J. (n.d.). nerf_pl/datasets/blender.py. GitHub. `https://github.com/kwea123 / nerf_pl / blob / master / datasets / blender.py`" />
        <br />
    
        <DownSpace />
    </div>
  )
}

export default NeRFFromScratch