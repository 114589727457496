import React from 'react'
import { Helmet } from 'react-helmet';

import CodeBlock from '../../components/CodeBlock'
import Title from '../../components/Title'
import Paragraph from '../../components/Paragraph'
import SubTitle from '../../components/SubTitle'
import ImageBlock from '../../components/ImageBlock'
import DownSpace from '../../components/DownSpace'
import ColabButton from '../../components/ColabButton'
import Banner from '../../components/Banner'

const imports = `!pip install opendatasets --upgrade --quiet
import torch, torchvision, PIL, os, numpy, opendatasets, tqdm, matplotlib.pyplot`

const transforms_1 = `high_resolution = 320
low_resolution = high_resolution // 4`

const transforms_2 = `highres_transform = torchvision.transforms.Compose([
  torchvision.transforms.ToTensor(),
  torchvision.transforms.Normalize(mean=[0.5, 0.5, 0.5], std=[0.5, 0.5, 0.5]),
])

lowres_transform = torchvision.transforms.Compose([
  torchvision.transforms.Resize(size=(low_resolution, low_resolution), interpolation=PIL.Image.BICUBIC),
  torchvision.transforms.ToTensor(),
  torchvision.transforms.Normalize(mean=[0, 0, 0], std=[1, 1, 1]),
])

both_transforms = torchvision.transforms.Compose([
  torchvision.transforms.RandomCrop(size=(high_resolution, high_resolution)),
  torchvision.transforms.RandomHorizontalFlip(p=0.5),
  torchvision.transforms.RandomApply([torchvision.transforms.RandomRotation(degrees=90)], p=0.5)
])`

const dataset_comp = `class Dataset(torch.utils.data.Dataset):
  def __init__(self, root_dir, is_valid=False):
    super(Dataset, self).__init__()
    self.data = []
    self.root_dir = root_dir
    self.image_files = os.listdir(root_dir)

    self.is_valid = is_valid

    for index, img_file in enumerate(self.image_files):
        self.data.append((img_file, index))

  def __len__(self):
    return len(self.data)

  def __getitem__(self, index):
    img_file = self.image_files[index]
    image_path = os.path.join(self.root_dir, img_file)

    image = PIL.Image.open(image_path).convert("RGB")

    if self.is_valid == False:
      image    =   both_transforms(image)
    else:
      image    =   torchvision.transforms.Resize((high_resolution, high_resolution))(image)

    high_res =   highres_transform(image)
    low_res  =   lowres_transform(image)

    return low_res, high_res`

const call_datas = `train_dataset = Dataset(root_dir="./animal-faces/afhq/train/dog")
val_dataset   = Dataset(root_dir="./animal-faces/afhq/val/dog", is_valid=True)`

const call_loaders = `train_loader = torch.utils.data.DataLoader(train_dataset, batch_size=16, shuffle=True, num_workers=2)
val_loader   = torch.utils.data.DataLoader(val_dataset, batch_size=1, shuffle=True, num_workers=2)`

const found_01 = `class ConvBlock(torch.nn.Module):
  def __init__(self, in_channels, out_channels, discriminator=False, use_act=True, use_bn=True, **kwargs):
    super().__init__()
    self.use_act = use_act

    self.cnn = torch.nn.Conv2d(in_channels, out_channels, **kwargs, bias=not use_bn)
    self.bn = torch.nn.BatchNorm2d(out_channels) if use_bn else torch.nn.Identity()
    self.act = (torch.nn.LeakyReLU(0.2, inplace=True) if discriminator else torch.nn.PReLU(num_parameters=out_channels))

  def forward(self, x):
    return self.act(self.bn(self.cnn(x))) if self.use_act else self.bn(self.cnn(x))`

const found_02 = `class UpsampleBlock(torch.nn.Module):
  def __init__(self, in_channels, scale_factor):
    super().__init__()

    self.conv = torch.nn.Conv2d(in_channels, in_channels*scale_factor**2, 3, 1, 1)
    self.ps = torch.nn.PixelShuffle(scale_factor)
    self.act = torch.nn.PReLU(num_parameters=in_channels)

  def forward(self, x):
    return self.act(self.ps(self.conv(x)))`

const found_03 = `class ResidualBlock(torch.nn.Module):
  def __init__(self, in_channels):
    super().__init__()

    self.block1 = ConvBlock(in_channels, in_channels, kernel_size=3, stride=1, padding=1)
    self.block2 = ConvBlock(in_channels, in_channels, kernel_size=3, stride=1, padding=1, use_act=False)

  def forward(self, x):
    out = self.block2(self.block1(x))
    return out + x`

const generator = `class Generator(torch.nn.Module):
  def __init__(self, in_channels=3, num_channels=64, num_blocks=32):
    super().__init__()

    self.initial = ConvBlock(in_channels, num_channels, kernel_size=9, stride=1, padding=4, use_bn=False)
    self.residuals = torch.nn.Sequential(*[ResidualBlock(num_channels) for _ in range(num_blocks)])
    self.convBlock = ConvBlock(num_channels, num_channels, kernel_size=3, stride=1, padding=1, use_act=False)
    self.upsamples = torch.nn.Sequential(UpsampleBlock(num_channels, scale_factor=2), UpsampleBlock(num_channels, 2))
    self.last = torch.nn.Conv2d(num_channels, in_channels, kernel_size=9, stride=1, padding=4)

  def forward(self, x):
    initial = self.initial(x)
    x = self.residuals(initial)
    x = self.convBlock(x)+initial
    x = self.upsamples(x)
    return torch.tanh(self.last(x))`
  
const discrim = `class Discriminator(torch.nn.Module):
  def __init__(self, in_channels=3, features=[64, 64, 128, 128, 256, 512, 512, 1024]):
    super().__init__()
    blocks = []

    for idx, feature in enumerate(features):
      blocks.append(ConvBlock(in_channels, feature, kernel_size=3, stride= 1+idx%2, padding=1, discriminator=True, use_act=True,
                              use_bn=False if idx==0 else True))
      in_channels = feature

    self.blocks = torch.nn.Sequential(*blocks)

    self.classifier = torch.nn.Sequential(torch.nn.AdaptiveAvgPool2d((6, 6)),
                                          torch.nn.Flatten(),
                                          torch.nn.Linear(1024*6*6, 1024),
                                          torch.nn.LeakyReLU(0.2, inplace=True),
                                          torch.nn.Linear(1024, 1))
  def forward(self, x):
    x = self.blocks(x)
    return self.classifier(x)`


const vgg = `class VGGLoss(torch.nn.Module):
  def __init__(self):
    super().__init__()

    self.vgg  = torchvision.models.vgg19(pretrained=True).features[:36].eval().to(device)
    self.loss = torch.nn.MSELoss()

    for param in self.vgg.parameters():
        param.requires_grad = False

  def forward(self, input, target):
    vgg_input_features = self.vgg(input)
    vgg_target_features = self.vgg(target)
    return self.loss(vgg_input_features, vgg_target_features)`


const training_function = `def training_function(data_loader, disc, gen, opt_gen, opt_disc, mse, bce, vgg_loss, use_phase_2, num_epochs):
  l1_losses = []

  for epoch in range(num_epochs):
      for idx, (low_res, high_res) in enumerate(tqdm.tqdm(data_loader, leave=True)):
          high_res = high_res.to(device)
          low_res = low_res.to(device)

          # Train the discriminator
          fake = gen(low_res)
          disc_real = disc(high_res)
          disc_fake = disc(fake.detach())
          disc_loss_real = bce(disc_real, torch.ones_like(disc_real))
          disc_loss_fake = bce(disc_fake, torch.zeros_like(disc_fake))
          loss_disc = disc_loss_fake + disc_loss_real

          opt_disc.zero_grad()
          loss_disc.backward()
          opt_disc.step()

          if use_phase_2:
              # Phase 2: VGG and adversarial
              disc_fake = disc(fake)
              adversarial_loss = 1e-3 * bce(disc_fake, torch.ones_like(disc_fake))
              loss_for_vgg = vgg_loss(fake, high_res) * 0.75
              gen_loss = loss_for_vgg + adversarial_loss
          else:
              # Phase 1: MSE only
              gen_loss = mse(fake, high_res)

          opt_gen.zero_grad()
          gen_loss.backward()
          opt_gen.step()

      # Calculate and store L1 loss between epochs
      with torch.no_grad():
          epoch_l1_loss = torch.nn.functional.l1_loss(fake, high_res).item()
          l1_losses.append(epoch_l1_loss)

      # Display average L1 loss at the end of each epoch
      avg_l1_loss = sum(l1_losses) / len(l1_losses)
      print(f'Epoch [{epoch+1}/{num_epochs}], Avg L1 Loss: {avg_l1_loss}')

  return l1_losses`

const evaluation = `def plot_examples(validation_loader, gen, num_examples=25):
  gen.eval()

  for i, (low_res, high_res) in enumerate(validation_loader):
    if i == num_examples:
        break

    # Display the low-resolution image
    fig, axs = matplotlib.pyplot.subplots(1, 3, figsize=(15, 5))

    axs[0].imshow(high_res[0].cpu().numpy().transpose(1, 2, 0) * 0.5 + 0.5)
    axs[0].set_title('Original Image')
    axs[0].axis('off')

    with torch.no_grad():
        # Generate the high-resolution image
        upscaled_img = gen(low_res.to(device))

    # Convert the tensors to NumPy arrays
    img_low_res_np = low_res.squeeze(0).cpu().numpy()
    img_high_res_np = upscaled_img.squeeze(0).cpu().numpy()

    # Normalize the images to the range [0, 1]
    img_low_res_np = (img_low_res_np)
    img_high_res_np = (img_high_res_np * 0.5 + 0.5).clip(0, 1)

    # Display the original and generated images side by side
    axs[1].imshow(img_low_res_np.transpose(1, 2, 0))
    axs[1].set_title('Low-Resolution Image')
    axs[1].axis('off')

    axs[2].imshow(img_high_res_np.transpose(1, 2, 0))
    axs[2].set_title('Generated High-Resolution Image')
    axs[2].axis('off')

    matplotlib.pyplot.show()

  gen.train()
  return`

const SuperResolution = () => {
  return (
    <div className='custom-font pt-9'>
        <Helmet>
          <title>Developing a Super-Resolution Image Upscaling Technique from Scratch Using PyTorch</title>
          <meta name="author" content="Dinis Martinho" />
          <meta name="description" content="In this article, I will guide you through the process of implementing a modified Super-Resolution Generative Adversarial Network (SRGAN), all within a single Jupyter notebook. Although SRGAN itself is no longer considered state-of-the-art, it paved the way for modern super-resolution techniques. By reading this, you will be capable of building an efficient customized image upscaling technique from scratch using PyTorch." />
        </Helmet>


        <Title Title="Developing a Super-Resolution Image Upscaling Technique from Scratch Using PyTorch" date='31 Jan 2024' />
        <br />
        <ColabButton notebookUrl="https://colab.research.google.com/drive/1g1Vc6TFkABhg8m3QzLD4kwtzPWiVB-ov" paperURL="https://arxiv.org/abs/1609.04802" />

        <SubTitle Title="Introduction" noMarginTop={true} />
        <br />
        <Paragraph text="In this article, I will guide you through the process of implementing a modified Super-Resolution Generative Adversarial Network (SRGAN), all within a single Jupyter notebook. Although SRGAN itself is no longer considered state-of-the-art, it paved the way for modern super-resolution techniques. By reading this, you will be capable of building an efficient customized image upscaling technique from scratch using PyTorch." />
        <br />
        <img className='w-auto' src={process.env.PUBLIC_URL + "/Developing-a-Super-Resolution-Image-Upscaling-Technique-from-Scratch-Using-PyTorch-I/b1.jpeg"} />
        <br />

        <Paragraph text="The concept of the Super-Resolution Generative Adversarial Network (SRGAN) was introduced by Christian Ledig and his colleagues in their 
        paper titled 'Photo-Realistic Single Image Super-Resolution Using a Generative Adversarial Network'. This influential paper was presented at the IEEE 
        Conference on Computer Vision and Pattern Recognition (CVPR) in 2017. The research introduced a novel approach to super-resolution tasks, which traditionally 
        relied on less advanced machine learning techniques." />
        <br />
        <Paragraph text="The innovative methods proposed by Ledig et al. involved the design of a pioneering deep learning algorithm tailored for image 
        super-resolution. This algorithm used a generator and discriminator network, while also integrating the concept of perceptual loss guiding. Perceptual loss, 
        introduced as a promising technique in the 2016 paper 'Perceptual Losses for Real-Time Style Transfer and Super-Resolution' by Justin Johnson, Alexandre 
        Alahi, and Li Fei-Fei, enhances the quality of the super-resolved images by comparing high-level features of the generated images with those of the original 
        images, rather than just pixel differences." />
        <br />
        <Paragraph text="It's important to mention that I utilized several great resources to learn about this topic. One of these resources is the YouTube video by 
        Aladdin Persson, where he demonstrates his own implementation of an SRGAN. Much of the code in my implementation was taken from his work. You can find a 
        reference to his video in the references section of this article. However, there are notable differences between my implementation and his, particularly 
        in the training methodology and model architecture. While I employ methods similar to Persson's and those described in the original SRGAN paper, I chose a 
        different approach to the training loss cycles and weights. It's also worth noting that I used a different dataset for training and did not evaluate the model 
        using the same processes outlined in the original paper." />
        <br />
        
        {/* 9/10 */}

        <SubTitle Title="Understanding SRGAN from a practical point of view" />
        <br />
        <Paragraph text="SRGAN operates on a sophisticated architecture that leverages Generative Adversarial Networks (GANs) to achieve high-quality image 
        super-resolution. The training methodology implemented in this Jupyter notebook consists of two cycles. The initial training cycle focuses on the generator's ability 
        to reconstruct images by minimizing the mean squared error (MSE) loss. This phase serves as a warm-up, allowing the generator to establish a fundamental 
        understanding of enhancing image resolution. By concentrating on basic reconstruction, the generator learns to produce super-resolved images that are 
        structurally similar to the low-resolution inputs, laying the groundwork for more advanced refinement." />
        <br />
        <Paragraph text="During the second training cycle, the focus shifts from basic reconstruction to a more nuanced understanding of image super-resolution. 
        Here, the integration of the discriminator and perceptual loss marks a pivotal stage in refining the generated outputs. The discriminator plays a 
        crucial role by discerning between real and generated images, providing feedback to the generator. This adversarial process compels the generator to 
        produce images that not only mimic high-resolution details but also adhere to the natural statistics and characteristics of real images. Additionally, 
        the incorporation of perceptual loss, derived from a pre-trained convolutional neural network, adds another layer of sophistication. By evaluating the 
        perceptual similarity between generated and ground truth images at higher semantic levels, the perceptual loss ensures that the super-resolved images 
        maintain not only structural fidelity but also perceptual realism. This iterative refinement process during the second cycle elevates the quality of the 
        generated images, ultimately leading to perceptually realistic outputs." />
        <br />

        {/* 10/10 */}
        
        <SubTitle Title="Importing the necessary libraries" />
        <br />
        <CodeBlock code={imports} />
        <br />

        <SubTitle Title="Downloading the dataset from Kaggle" />
        <br />
        <Paragraph text="For this project, I chose to train my SRGAN using dog faces obtained from the 'Animal Faces' dataset on Kaggle. As mentioned earlier, I did not utilize the same dataset as the one used in the paper or Aladdin Persson's implementation. The dataset will be downloaded using the `opendatasets` library, which requires an API key. You can obtain this key for free by creating a Kaggle account. A reference to this dataset can be found in the references section of this article." />
        <br />
        <Paragraph text="The dataset comprises various animal faces categorized into dogs, cats, and wild animals. For this project, I've decided to use only the subset containing dog images. Further details about this dataset can be found on Kaggle. It's important to note that if you decide to use a different dataset, certain details and components might not work properly or as intended, and you might need to modify some code." />
        <br />
        <CodeBlock code='opendatasets.download("https://www.kaggle.com/datasets/andrewmvd/animal-faces")' />
        <br />

        <SubTitle Title="Creating our custom dataset and data loader components" />
        <br />
        <Paragraph text="Unlike Aladdin Persson's implementation, my super-resolution model will be trained to generate predictions from a downsampled 4x image of size `320x320`. Specifically, the task involves predicting a high-resolution image at `320x320` pixels from a downsampled version reduced to `80x80` pixels. While Aladdin Persson's implementation seemed to attempt predicting smaller patches of images to conserve memory usage, I've found that this approach significantly impacted the output results negatively and took longer to converge." />
        <br />
        <CodeBlock code={transforms_1} />
        <br />
        <Paragraph text="Similar to Aladdin Persson's implementation, I've defined three data transforms for my dataset: one designed to be applied to the low-resolution images, another for the high-resolution images, and the last one for both resolutions. The decision to incorporate crops into the training process is motivated by the small amount of data in our dataset." />
        <br />
        <CodeBlock code={transforms_2} />
        <br />
        <CodeBlock code={dataset_comp} />
        <br />
        <CodeBlock code={call_datas} />
        <br />
        <CodeBlock code={call_loaders} />
        <br />

        <SubTitle Title="Implementing the core modules for our SRGAN architecture" />
        <br />
        <Paragraph text="I will be implementing the core modules in both the generator and discriminator models for my SRGAN. Most of the following code is copied from Aladdin Persson's implementation. It is important to note that some of the processes and techniques used in this implementation are not explicitly explained in the paper, and are mostly left to interpretation and intuition. You can find a visual representation of these modules and the complete generator and discriminator models on the fourth page of the paper." />
        <br />
        <Paragraph text="Even so, I will try to provide an overview explanation for each component and some of the possible reasons why certain layers and components were used and chosen by the authors of the paper." />
        <br />
        <Paragraph text="The `ConvBlock` module is integrated into several modules outlined in the paper. The paper discusses two potential representations for this module: one for the generator and another for the discriminator. However, just like Aladdin Persson, I chose to implement a unified block like this one, which can be used across both the generator and discriminator. In this approach, the module used in the generator mirrors the one used in the discriminator, albeit with a different activation function. Additionally, it includes twice the number of layers, as seen in the yet-to-be-defined residual block, and sometimes doesn't utilize any normalization layer in certain specific parts of both model's architectures." />
        <br />
        <CodeBlock code={found_01} />
        <br />
        <Paragraph text="As expected from a component that will be used for both generative and classification purposes, it includes logic to adapt the activation functions: using Leaky ReLU for the discriminator and Parametric ReLU for the generator. Leaky ReLU introduces a small gradient for negative inputs, aiding in stability for classification tasks during training, which is particularly useful in discriminator networks. Meanwhile, Parametric ReLU extends this concept with learnable parameters, allowing adaptive slope adjustment per neuron, which is beneficial for complex generative architectures like the SRGAN generator." />
        <br />
        <Paragraph text="This `UpsampleBlock` module is a direct implementation from the one shown in the paper, and it's used by the generator to increase spatial dimensions within the network. It utilizes pixel shuffle and Parametric ReLU in order to efficiently upscale the feature maps, thus enhancing the overall resolution of the generated images." />
        <br />
        <CodeBlock code={found_02} />
        <br />
        <Paragraph text="The usage of pixel shuffle likely originates from the need to efficiently upscale feature maps while preserving spatial information. This technique involves reorganizing the elements of feature maps to increase the spatial resolution of an image, thereby enabling the generation of more detailed and visually appealing results." />
        <br />
        <Paragraph text="The `ResidualBlock` module is designed to preserve pixel dimensions and integrates the `ConvBlock` module, as defined earlier, twice. This block preserves both input and output information by summing them together, thus aiding in mitigating issues such as gradient vanishing or explosion during training. This module is exclusively employed by the generator model and is not utilized by the discriminator." />
        <br />
        <CodeBlock code={found_03} />
        <br />

        <SubTitle Title="Implementing the generator model" />
        <br />
        <Paragraph text="Now we can assemble the architecture of the generator with our previously created modules. The generator's architecture is quite simple, primarily consisting of a sequence of `ConvBlock` modules and residual layers. The actual upsampling occurs in the final layers of the network, followed by a final convolution and TanH activation function." />
        <br />
        <CodeBlock code={generator} />
        <br />

        <SubTitle Title="Implementing the discriminator model" />
        <br />
        <Paragraph text="The discriminator's architecture is a very simple sequential architecture of `ConvBlock` modules, followed by two fully connected layers. The discriminator should output a tensor of size `[Batch_size, 1]` such that for each item within the batch, it provides a probability of it being a real or fake image." />
        <br />
        <CodeBlock code={discrim} />
        <br />
        <Paragraph text="Some discriminator architectures also allow the low-resolution variant of the input image to be fed alongside the fake and real images. This enables the discriminator to compare the generated output with its corresponding low-resolution input, assisting in the detection of artifacts or inconsistencies between them. This particular method wasn't utilized in the SRGAN paper, so I didn't implement it either." />
        <br />
        

        <SubTitle Title="Implementing the perceptual loss module" />
        <br />
        <Paragraph text="The perceptual loss in the SRGAN context involves comparing the high-level features extracted from a pre-trained VGG network from the generated and target images.
        By minimizing this feature discrepancy, the generator is encouraged to produce images with enhanced perceptual similarity, aligning them more closely with the 
        true high-resolution images. This perceptual loss helps achieve improved visual quality in the super-resolved outputs." />
        <br />
        <CodeBlock code={vgg} />
        <br />
        <Paragraph text="In this case, we are extracting features from both the input and target images using a pre-trained VGG19 network. The VGG19 model is loaded with pre-trained weights, and only the first 36 layers (features) are used for feature extraction. These features are then compared using a mean squared error loss function, which computes the difference between the feature representations of the input and target images. " />
        <br />

        <SubTitle Title="Implementing the training function" />
        <br />
        <Paragraph text="As mentioned earlier, I've implemented a two-cycle approach to the training function. The first cycle employs a warm-up phase with Mean Squared Error (MSE) loss exclusively for the generator model. Once the generator begins to understand the fundamental reconstruction task, we transition to the second cycle, which incorporates the discriminator and perceptual losses to guide the generator in capturing finer details in the image, such as sharp fur in this case." />
        <br />
        <CodeBlock code={training_function} />
        <br />
        <Paragraph text="One of the most critical aspects of this training function is the weighting of each loss during the second cycle. I've chosen the values of `1e-3` and `0.75` for the discriminator and perceptual loss. It's crucial to experiment with various values since they impact the stability during training and the learning speed of the network." />
        <br />

        <SubTitle Title="Training the SRGAN" />
        <br />
        <CodeBlock code='device = torch.device("cuda" if torch.cuda.is_available() else "cpu")' />
        <br />
        <CodeBlock code="gen  = Generator(in_channels=3).to(device)
disc = Discriminator(in_channels=3).to(device)" />
        <br />
        <CodeBlock code="mse = torch.nn.MSELoss()
bce = torch.nn.BCEWithLogitsLoss()
vgg_loss = VGGLoss()" />
        <br />
        <Paragraph text="I've achieved favorable results by conducting the first training phase with a learning rate of `1e-3`, utilizing the Adam optimizer over a span of 5 epochs." />        
        <br />
        <CodeBlock code="opt_gen  = torch.optim.Adam(gen.parameters(), lr=1e-3, betas=(0.9, 0.999))
opt_disc = torch.optim.Adam(disc.parameters(), lr=1e-3, betas=(0.9, 0.999))" />
        <br />
        <CodeBlock code="training_function(train_loader, disc, gen, opt_gen, opt_disc, mse, bce, vgg_loss, use_phase_2=False, num_epochs=5)" />
        <br />
        <Paragraph text="In the second phase, I've attained satisfactory results by training for 25 epochs with a learning rate of `1E-4`, utilizing the Adam optimizer." />
        <br />
        <CodeBlock code="opt_gen  = torch.optim.Adam(gen.parameters(), lr=1e-4, betas=(0.9, 0.999))
opt_disc = torch.optim.Adam(disc.parameters(), lr=1e-4, betas=(0.9, 0.999))" />
        <br />
        <CodeBlock code="training_function(train_loader, disc, gen, opt_gen, opt_disc, mse, bce, vgg_loss, use_phase_2=True, num_epochs=25)" />
        <br />
        <Paragraph text="Training an SRGAN involves significant experimentation and demands robust computational resources. It's advisable to explore varying loss 
        weights, especially in phase 2, to optimize the model's performance. Alternatively, if you don't desire to train your own, you can download pretrained weights from my 
        own training session." />
        <br />
        <CodeBlock code="!pip install -U --no-cache-dir gdown --pre
!gdown --no-cookies 1NNvM6JJRCtGgzfGn9a8YXJM4kXeAPwZ1
!gdown --no-cookies 1ooKFUrF98r5N7-IUYdhKexkptCNmBEm6" />
        <br />
        <CodeBlock code="gen.load_state_dict(torch.load('gen.pth'))
disc.load_state_dict(torch.load('disc.pth'))" />
        <br />

        <SubTitle Title="Evaluating our generator model" />
        <br />
        <Paragraph text="Now we can utilize our validation data loader to assess the generator's performance. Observing the outputs reveals a notable smoothing 
        effect, which could indicate an imbalance in the loss weights. This might be due to elevated VGG loss weighting or insufficient adversarial loss weighting. 
        Despite this, the overall image quality remains satisfactory for a relatively simple project." />
        <br />
        <CodeBlock code={evaluation} />
        <br />
        <CodeBlock code="plot_examples(val_loader, gen)" />
        <br />
        <img className='w-auto' src={process.env.PUBLIC_URL + "/Developing-a-Super-Resolution-Image-Upscaling-Technique-from-Scratch-Using-PyTorch-I/b4.jpeg"} />
        <img className='w-auto' src={process.env.PUBLIC_URL + "/Developing-a-Super-Resolution-Image-Upscaling-Technique-from-Scratch-Using-PyTorch-I/b5.jpeg"} />
        <img className='w-auto' src={process.env.PUBLIC_URL + "/Developing-a-Super-Resolution-Image-Upscaling-Technique-from-Scratch-Using-PyTorch-I/b6.jpeg"} />
        <img className='w-auto' src={process.env.PUBLIC_URL + "/Developing-a-Super-Resolution-Image-Upscaling-Technique-from-Scratch-Using-PyTorch-I/b3.jpeg"} />
        <img className='w-auto' src={process.env.PUBLIC_URL + "/Developing-a-Super-Resolution-Image-Upscaling-Technique-from-Scratch-Using-PyTorch-I/a1.jpeg"} />
        <br />


        <SubTitle Title="Summative insights and future considerations" /> 
        <br />
        <Paragraph text="In conclusion, you now have the proficiency to implement a foundational super-resolution technique. The techniques outlined in 
        this guide can serve as a basis for more advanced approaches such as ESRGAN or other SRGAN variants. For optimal results, especially when dealing 
        with animal images featuring fur, this model has demonstrated its effectiveness. However, it's crucial to acknowledge that its performance might 
        diminish for tasks beyond this specific domain." />
        <br />
        <Paragraph text="Future endeavors could focus on exploring ESRGAN models, experimenting with diverse SRGAN variants, 
        and refining the methodology to excel in a broader spectrum of super-resolution applications. For additional insights and guidance, I highly 
        recommend referring to Aladdin Persson's YouTube channel, which proved invaluable to me in completing this particular implementation." />
        <br />

        
        <SubTitle Title="Resources used" />
        <br />
        <Paragraph text="▸ [1] Ledig, C., Theis, L., Huszar, F., Caballero, J., Cunningham, A., Acosta, A., Aitken, A., Tejani, A., Totz, J., Wang, Z., & Shi, W. (2016). Photo-Realistic Single Image Super-Resolution Using a Generative Adversarial Network. arXiv preprint `arXiv:1609.04802.`" />
        <br />
        <Paragraph text="▸ [2] Johnson, J., Alahi, A., & Li, F.-F. (2016). Perceptual Losses for Real-Time Style Transfer and Super-Resolution. arXiv preprint `arXiv:1603.08155`" />
        <br />
        <Paragraph text="▸ [3] Kaggle. (n.d.). Animal Faces. Kaggle. `https://www.kaggle.com / datasets / andrewmvd / animal-faces`" />
        <br />
        <Paragraph text="▸ [4] Persson, A. (2021, August 12). SRGAN implementation from scratch [Video]. YouTube. `https://youtu.be / 7FO9qDOhRCc?si= UOtpOIB8N2KDQplI`" />
        <br />
        
        <DownSpace />

    </div>
  )
}

export default SuperResolution