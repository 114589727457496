import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DownSpace from '../components/DownSpace';
import { Helmet } from 'react-helmet';


const Thumbnail = ({ title, description, imageSrc, to }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showFullTitle, setShowFullTitle] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const customClass = isHovered ? 'underline underline-offset-1' : '';

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const toggleTitle = () => {
    setShowFullTitle(!showFullTitle);
  };

  return (
    <Link
      to={to}
      className="text-justify border border-gray-200 mb-0 relative max-w-xs overflow-hidden rounded shadow-xl transition-transform transform hover:scale-[1.00] hover:shadow-4xl hover:shadow-gray-300 shadow-gray-200 transition-all duration-300"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span className="font-bold absolute top-2 right-[0.5rem] bg-white text-black px-2 py-1 rounded-[80%] shadow-3xl text-xl hidden "> e</span>
      <span className="font-bold absolute top-2 right-[3.25rem] bg-white text-black px-2 py-1 rounded-[80%] shadow-3xl text-xl hidden "> e</span>
      <img
        className="w-full h-auto max-h-40 object-cover" // hidden sm:block
        src={imageSrc}
        alt={title}
      />
      <div className="px-6 py-4">
        <div
          className="text-lg mb-2 text-left font-bold"
          style={{
          }}
        >
          {showFullTitle ? title : `${title.slice(0, 120)}`}
          {title.length > 120 && (
            <button
              onClick={toggleTitle}
              className="text-blue-500 hover:underline focus:outline-none ml-2 hidden"
            >
              {showFullTitle ? 'Read Less' : 'Read More'}
            </button>
          )}
        </div>
        <p
          className="text-gray-600 text-base" // Set the color to a slightly gray shade
          style={{
            transform: showFullDescription ? 'scale(1)' : 'scale(1)',
          }}
        >
          {showFullDescription ? description : `${description.slice(0, 100)}...`}
          {description.length > 100 && (
            <button
              onClick={toggleDescription}
              className={`${customClass} text-orange-600 hover:underline focus:outline-none mt-2 block`}
            >
              {showFullDescription ? 'See Less' : 'See More'}
            </button>
          )}
        </p>
      </div>
    </Link>
  );
};



const BlogPostContainer = ({ posts }) => {
  return (
    <div className="custom-font pt-4 mx-auto max-w-screen-xl grid gap-6 sm:gap-6 md:gap-6 lg:gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      {posts.map((post, index) => (
        <Thumbnail key={index} {...post} />
      ))}
    </div>
  );
};

const Repos = () => { 
  const blogPosts = [

    {
      title: "Research Contributions",
      description: "This repository encompasses a collection of my research and contributions across various online competitions and related areas.",
      imageSrc: process.env.PUBLIC_URL + "/thumbnails/research.jpeg",
      to: "/Repos/Personal-Research",
    },
    {
      title: "Personal Projects",
      description: "This repository contains a collection of my personal projects, created for both recreational enjoyment and educational purposes.",
      imageSrc: process.env.PUBLIC_URL + "/thumbnails/personal-projects.jpeg",
      to: "/Repos/Personal-Projects",
    },

    //{
     // title: "Released Products",
      //description: "This repository encompasses all of my released products or production-ready software written or influenced by me.",
      //imageSrc: process.env.PUBLIC_URL + "/thumbnails/server.jpeg",
      //to: "/Repos/Production-Ready",
    //},

  ];

  return (
    <div>
      <Helmet>
          <title>Dinis Martinho</title>
          <meta name="author" content="Dinis Martinho" />
          <meta name="description" content="Browse through several blog posts that guide you on how to implement iconic machine learning papers." />
      </Helmet>

      <div className="custom-font pt-5 flex justify-center mb-6">
           <BlogPostContainer posts={blogPosts} />
      </div>
      
    </div>
  );
};

export default Repos;
