import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DownSpace from '../components/DownSpace';
import { Helmet } from 'react-helmet';


const Thumbnail = ({ title, description, imageSrc, to, blueReadMore = false, date='31 Jan 2024' }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showFullTitle, setShowFullTitle] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const customClass = isHovered ? 'underline underline-offset-1' : '';
  const readMoreColor = blueReadMore ? 'text-blue-600' : 'text-orange-600';

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const toggleTitle = () => {
    setShowFullTitle(!showFullTitle);
  };

  return (
    <Link
      to={to}
      className="text-justify border border-gray-200 mb-0 relative max-w-xs overflow-hidden rounded shadow-xl transition-transform transform hover:scale-[1.00] hover:shadow-4xl hover:shadow-gray-300 shadow-gray-200 transition-all duration-300"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span className="hidden font-bold rounded-bl-lg absolute top-0 right-[0rem] text-bold backdrop-blur-lg text-white px-2 py-1 shadow-lg text-[12px] border-b-2 border-l-2 border-white">{date}</span>
      <img
        className="w-full h-auto max-h-40 object-cover" // hidden sm:block
        src={imageSrc}
        alt={title}
      />
      <div className="px-6 py-4">
        <div
          className="text-lg mb-2 text-justify font-bold"
          style={{
          }}
        >
          {showFullTitle ? title : `${title.slice(0, 120)}`}
          {title.length > 120 && (
            <button
              onClick={toggleTitle}
              className={`${readMoreColor} hover:underline focus:outline-none ml-2 hidden`}
            >
              {showFullTitle ? 'Read Less' : 'Read More'}
            </button>
          )}
        </div>
        <p
  className="text-gray-600 text-base" // Set the color to a slightly gray shade
  style={{
    transform: showFullDescription ? 'scale(1)' : 'scale(1)',
  }}
>
  {showFullDescription ? description : `${description.slice(0, 100)}...`}
  {description.length > 100 && (
    <div className="flex justify-between items-center mt-2">
      <button
        onClick={toggleDescription}
        className={`${customClass} ${readMoreColor} hover:underline focus:outline-none block`}
      >
        {showFullDescription ? 'Read Less' : 'Read More'}
      </button>
      <span className="text-[13px] text-black">{date}</span>
    </div>
  )}
</p>

      </div>
    </Link>
  );
};



const BlogPostContainer = ({ posts }) => {
  return (
    <div className="custom-font pt-0 mx-auto max-w-screen-xl grid gap-6 sm:gap-6 md:gap-6 lg:gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      {posts.map((post, index) => (
        <Thumbnail key={index} {...post} />
      ))}
    </div>
  );
};

const scraps = () => { // black and white abstract otter, photorealistic, orange shadows, comic, sharp image
  const blogPosts = [ //   prompt = "t" #@param {type:"string"} BIRD ESTA EMPTY BIRD ESTA EMPTY BIRD ESTA EMPTY BIRD ESTA EMPTY
    //{
    //  title: "Developing a Super-Resolution Image Upscaling Technique from Scratch Using PyTorch",
    //  description: "Within this blog post, discover a step-by-step guide on developing, training, and testing an image upscaling model using PyTorch.",
    //  imageSrc: process.env.PUBLIC_URL + "/thumbnails/dog.jpeg",
    //  to: "/Scraps/Developing-a-Super-Resolution-Image-Upscaling-Technique-from-Scratch-Using-PyTorch",
    //},
   
    //{
      //title: "Designing, Training and Testing a Transformer Powered Translator Network from Scratch Using Pytorch",
      //description: "In this post, explore a guide on designing, training, and testing a transformer network created to translate between different written languages.",
      //imageSrc: process.env.PUBLIC_URL + "/thumbnails/dog.jpeg",
      //to: "/Scraps/Designing-Training-and-Testing-a-Transformer-Powered-Translator-Network-from-Scratch-Using-Pytorch",
    //},
    //Data scraping maybe
    //{
    //  title: "Transformer-Powered Non-Conditional Latent Image Generation from Scratch within PyTorch",
    //  description: "Discover a comprehensive guide to constructing a non-conditional image generation model from scratch using PyTorch. Dive into the intricacies of transformer-powered latent image generation, exploring the fusion of advanced neural network architectures and deep learning techniques.",
    //  imageSrc: process.env.PUBLIC_URL + "/thumbnails/mouse.jpeg",
    //  to: "/Notes/Transformer-Powered-Non-Conditional-Latent-Image-Generation-from-Scratch-within-PyTorch",
    //  blueReadMore: true, // BLUE GOLDEN instead of orange
    //},
    //{
    //  title: "Applying Advanced Object Detection Techniques on Custom Data Using the YOLOv10 Pipeline",
   //   description: "Discover methods for applying YOLO's advanced object detection pipeline to personalized datasets in Python.",
    //  imageSrc: process.env.PUBLIC_URL + "/thumbnails/bird.jpeg",
    //  to: "/Notes/Applying-Advanced-Object-Detection-Techniques-on-Custom-Data-Using-the-YOLO-Pipeline",
    //  date: '26 Jul 2024',
    //},
    //{ 
    //  title: "Writting High-Performance Smooth Particle Hydrodynamics Simulations in C++ Using OpenGL",
    //  description: "Discover a step-by-step article on developing smooth particle hydrodynamics simulations from scratch using C++ and OpenGL. Learn the fundamentals of SPH, advanced computational techniques, and how to leverage OpenGL for efficient and realistic visualizations.",
    //  imageSrc: process.env.PUBLIC_URL + "/thumbnails/bird.jpeg",
    //  to: "/Notes/Writting-High-Performance-Smooth-Particle-Hydrodynamics-Simulations-in-C++-Using-OpenGL",
    //  date: '25 Jul 2024',
    //},
    //{   
    //  title: "Implementing Simple Smooth Particle Hydrodynamics Simulations from Scratch in Python",
    //  description: "Explore a straightforward guide on how to implement simple smoothed particle hydrodynamics simulations in Python.",
    //  imageSrc: process.env.PUBLIC_URL + "/thumbnails/giraffe.jpeg",
    //  to: "/Notes/Implementing-Simple-Smooth-Particle-Hydrodynamics-Simulations-from-Scratch-in-Python",
    //  date: '28 Jun 2024',
    //},
    //{
    //  title: "Transformer-Powered Non-Conditional Latent Image Generation from Scratch within PyTorch",
    //  description: "Discover a comprehensive guide to constructing a non-conditional image generation model from scratch using PyTorch. Dive into the intricacies of transformer-powered latent image generation, exploring the fusion of advanced neural network architectures and deep learning techniques.",
    //  imageSrc: process.env.PUBLIC_URL + "/thumbnails/bird.jpeg",
    //  to: "/Notes/Transformer-Powered-Non-Conditional-Latent-Image-Generation-from-Scratch-within-PyTorch",
    //},
    //{ 
    //  title: "Using Containers to Streamline PyTorch Algorithms for Efficient Machine Learning Inference",
    //  description: "In this article, explore a guide on using Docker containers to streamline PyTorch algorithms for efficient machine learning inference.",
    //  imageSrc: process.env.PUBLIC_URL + "/thumbnails/bird.jpeg",
    //  to: "/Notes/Using-Containers-to-Streamline-PyTorch-Algorithms-for-Efficient-Machine-Learning-Inference",
    //  date: '06 Jun 2024',
    //},
    { // 9.0 / 10, tema facil, simples explicado
      title: "State-of-the-Art Latent Space Quantization with VQ-GANs from Scratch Using Pytorch",
      description: "In this article, explore a guide on implementing a powerful VQ-GAN capable of encoding and decoding images within a fully discrete latent space.",
      imageSrc: process.env.PUBLIC_URL + "/thumbnails/fox.jpeg",
      to: "/Notes/State-of-the-Art-Latent-Space-Quantization-with-VQ-GANs-from-Scratch-Using-Pytorch",
      date: '30 Apr 2024',
    },
    { // 8.0 / 10, tema dificil, nao expliquei matematicas, mas overall bom
      title: "Building a NeRF Technique for Synthetic Scene Reconstruction from Scratch Using Pytorch",
      description: "Find within this notebook a step-by-step guide to building a Neural Radiance Field (NeRF) technique for synthetic scene reconstruction from scratch using PyTorch. Explore the intricacies of 3D graphics and computer vision in a concise, informative journey.",
      imageSrc: process.env.PUBLIC_URL + "/thumbnails/cat.jpeg",
      to: "/Notes/Building-a-NeRF-Technique-for-Synthetic-Scene-Reconstruction-from-Scratch-Using-Pytorch",
      date: '13 Feb 2024',
    },
    { // 8 / 10, tema facil, podia ser melhor explicado.
      title: "Developing a Super-Resolution Image Upscaling Technique from Scratch Using PyTorch",
      description: "Within this notebook, discover a step-by-step guide on developing, training, and testing an image upscaling model using PyTorch.",
      imageSrc: process.env.PUBLIC_URL + "/thumbnails/dog.jpeg",
      to: "/Notes/Developing-a-Super-Resolution-Image-Upscaling-Technique-from-Scratch-Using-PyTorch",
      date: '31 Jan 2024',
    },
  
    // {
      // title: "Building a State-Of-The-Art Generative Adversarial Technique for Image Colorization using Pytorch",
      // description: "In this blog post, explore a complete PyTorch notebook providing a step-by-step implementation of a state-of-the-art colorization technique.",
      // imageSrc: process.env.PUBLIC_URL + "/thumbnails/dog.jpeg",
      // to: "/Scraps/Building-a-State-Of-The-Art-Generative-Adversarial-Technique-for-Image-Colorization-using-Pytorch",
    // },
    
  ];

  return (
    <div>
      <Helmet>
          <title>Dinis Martinho</title>
          <meta name="author" content="Dinis Martinho" />
          <meta name="description" content="I'm Dinis Martinho, Passionate about artificial intelligence, currently pursuing studies in Organizational Informatics and Communication at the University of Aveiro. Explored machine learning with a focus on computer vision applications and engaged in continuous self-directed learning and hands-on projects. Secured the fifth position in the MICCAI23 ARCADE competition as part of TeamZERO, contributing to Coronary Artery Disease diagnostics using machine learning and medical imaging." />
      </Helmet>

      <div className="custom-font pt-[40px] flex justify-center mb-12">
           <BlogPostContainer posts={blogPosts} />
      </div>

    </div>
  );
};

export default scraps;
