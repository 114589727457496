import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();

  return (
    <header className="fixed w-full bg-white p-5 z-20">
      <div className='flex items-center justify-center'>

        {/*
        <div
          className={`text-black px-7 sm:px-8 md:px-10 lg:px-10 custom-font text-[18px] md:text-[20px] sm:text-xl ${location.pathname.includes('/Repos') ? 'underline hover:underline font-bold underline-offset-8' : 'hover:underline underline-offset-8'}`}
        >
          <Link to="/Repos">/Repos</Link>
        </div>

        {/*
        <div
          className={`text-black px-6 sm:px-7 md:px-8 lg:px-8 custom-font text-xl md:text-[20px] sm:text-xl ${location.pathname.includes('/Tools') || location.pathname === '/Tools' ? 'underline hover:underline font-bold underline-offset-8' : 'hover:underline underline-offset-8'}`}
        >
          <Link className='cursor-pointer' to="/Tools">/Tools</Link>
        </div>

        {/* Scraps */}
        <div
          className={`text-black px-6 sm:px-7 md:px-8 lg:px-8 custom-font text-[20px] md:text-[21px] sm:text-xl ${location.pathname.includes('/Notes') || location.pathname === '/' ? 'underline hover:underline font-bold underline-offset-8' : 'hover:underline underline-offset-8'}`}
        >
          <Link className='cursor-pointer' to="/Notes">/Notes</Link>
        </div>
        
        

        {/* Products */}

        {/* 
        <div
          className={`text-black px-10 custom-font text-xl ${location.pathname === '/Products' ? 'underline hover:underline font-bold underline-offset-8' : 'hover:underline underline-offset-8'}`}
        >
          <Link to="/Products">/Products</Link>
        </div>
        {/* */}

        {/* About */}
        <div
          className={`text-black px-6 sm:px-7 md:px-8 lg:px-8 custom-font text-[20px] md:text-[21px] sm:text-xl ${location.pathname === '/About' ? 'underline hover:underline font-bold underline-offset-8' : 'hover:underline underline-offset-8'}`}
        >
          {/*<a href="https://github.com/DinisMartinho" target="_blank" rel="noopener noreferrer">
            /About
          </a>*/}
          <Link className='cursor-pointer' to="/About">/About</Link>
        </div>
        
      </div>
    </header>
  );
};

export default Header;
