import React from 'react'
import { Helmet } from 'react-helmet';

import CodeBlock from '../../components/CodeBlock'
import Title from '../../components/Title'
import Paragraph from '../../components/Paragraph'
import SubTitle from '../../components/SubTitle'
import ImageBlock from '../../components/ImageBlock'
import DownSpace from '../../components/DownSpace'
import ColabButton from '../../components/ColabButton'
import Banner from '../../components/Banner'


const YOLO = () => {
  return (
    <div className='custom-font pt-9'>
        <Helmet>
          <title>Applying Advanced Object Detection Techniques on Custom Data Using the YOLOv10 Pipeline</title>
          <meta name="author" content="Dinis Martinho" />
          <meta name="description" content="" />
        </Helmet>


        <Title Title="Applying Advanced Object Detection Techniques on Custom Data Using the YOLOv10 Pipeline" date='31 Jan 2024' />
        <br />
        <ColabButton notebookUrl="" paperURL="https://arxiv.org/abs/1609.04802" />

        <SubTitle Title="Introduction" noMarginTop={true} />
        <br />
    </div>
  )
}

export default YOLO