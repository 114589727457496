import React from 'react';

const ColabButton = ({ notebookUrl, paperURL }) => {
    const openInColab = () => {
      window.open(notebookUrl, '_blank');
    };

    const openInPaper = () => {
      window.open(paperURL, '_blank');
    };
  
    return (
      <div style={{ display: 'flex' }} className='content-end justify-end m-0 mb-4'>
      {notebookUrl && (<button
        onClick={openInColab}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        className='justify-end m-1 mx-0 content-end text-end rounded ml-3'
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="24"
          height="24"
          viewBox="0 0 48 48"
        >
          <path fill="#ffb300" d="M33.5,10C26.044,10,20,16.044,20,23.5C20,30.956,26.044,37,33.5,37S47,30.956,47,23.5	C47,16.044,40.956,10,33.5,10z M33.5,30c-3.59,0-6.5-2.91-6.5-6.5s2.91-6.5,6.5-6.5s6.5,2.91,6.5,6.5S37.09,30,33.5,30z"></path>
          <path fill="#ffb300" d="M19.14,28.051l0-0.003C17.96,29.252,16.318,30,14.5,30C10.91,30,8,27.09,8,23.5s2.91-6.5,6.5-6.5	c1.83,0,3.481,0.759,4.662,1.976l3.75-6.024C20.604,11.109,17.683,10,14.5,10C7.044,10,1,16.044,1,23.5C1,30.956,7.044,37,14.5,37	c3.164,0,6.067-1.097,8.369-2.919L19.14,28.051z"></path>
          <path fill="#f57c00" d="M8,23.5c0-1.787,0.722-3.405,1.889-4.58l-4.855-5.038C2.546,16.33,1,19.733,1,23.5	c0,3.749,1.53,7.14,3.998,9.586l4.934-4.964C8.74,26.944,8,25.309,8,23.5z"></path>
          <path fill="#f57c00" d="M38.13,18.941C39.285,20.114,40,21.723,40,23.5c0,3.59-2.91,6.5-6.5,6.5	c-1.826,0-3.474-0.755-4.655-1.968l-4.999,4.895C26.298,35.437,29.714,37,33.5,37C40.956,37,47,30.956,47,23.5	c0-3.684-1.479-7.019-3.871-9.455L38.13,18.941z"></path>
        </svg>
        <div className='pl-1 sm:pl-3 md:pl-3 lg:pl-3 font-bold justify-end custom-font text-black text-[14px] sm:text-[15px] md:text-[16px] lg:text-[16px]'>Open in Colaboratory</div>
      </button>
      )}

      {paperURL && (<button
        onClick={openInPaper}
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        className='justify-end m-1 mx-0 content-end text-end rounded ml-3'
      >
        <svg id="logomark" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 17.732 24.269"><g id="tiny"><path d="M573.549,280.916l2.266,2.738,6.674-7.84c.353-.47.52-.717.353-1.117a1.218,1.218,0,0,0-1.061-.748h0a.953.953,0,0,0-.712.262Z" transform="translate(-566.984 -271.548)" fill="#bdb9b4"/><path d="M579.525,282.225l-10.606-10.174a1.413,1.413,0,0,0-.834-.5,1.09,1.09,0,0,0-1.027.66c-.167.4-.047.681.319,1.206l8.44,10.242h0l-6.282,7.716a1.336,1.336,0,0,0-.323,1.3,1.114,1.114,0,0,0,1.04.69A.992.992,0,0,0,571,293l8.519-7.92A1.924,1.924,0,0,0,579.525,282.225Z" transform="translate(-566.984 -271.548)" fill="#b31b1b"/><path d="M584.32,293.912l-8.525-10.275,0,0L573.53,280.9l-1.389,1.254a2.063,2.063,0,0,0,0,2.965l10.812,10.419a.925.925,0,0,0,.742.282,1.039,1.039,0,0,0,.953-.667A1.261,1.261,0,0,0,584.32,293.912Z" transform="translate(-566.984 -271.548)" fill="#bdb9b4"/></g></svg>

        <div className='pl-1 sm:pl-3 md:pl-3 lg:pl-3 font-bold custom-font text-black text-[14px] sm:text-[15px] md:text-[16px] lg:text-[16px]'>Open Paper</div>
      </button>
      )}

      </div>
  );
};

export default ColabButton;