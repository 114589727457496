import React from 'react';

const Section = ({ children, className }) => {
  return (
    <section className={className}>
      {children}
    </section>
  );
};

const SubTitle = ({ Title, noMarginTop }) => {
  return (
    <Section className={`font-extrabold ${noMarginTop ? 'mt-0' : 'mt-6'}`}>
      <span className='font-bold text-xl'>+ </span>
      <span className='text-lg md:text-xl'>{Title}</span>
    </Section>
  );
};

export default SubTitle;