import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import CodeBlock from '../../components/CodeBlock'
import Title from '../../components/Title'
import Paragraph from '../../components/Paragraph'
import SubTitle from '../../components/SubTitle'
import ImageBlock from '../../components/ImageBlock'
import DownSpace from '../../components/DownSpace'
import ColabButton from '../../components/ColabButton'
import Banner from '../../components/Banner'

import EquationRenderer from '../../components/EquationRenderer';

const imports = `import numpy, math, matplotlib; from IPython.display import HTML; import matplotlib.animation`

const p1 = `class Particle:
  def __init__(self, pos_x, pos_y, gravity):
    self.pos_x, self.pos_y                   = pos_x, pos_y
    self.previous_pos_x, self.previous_pos_y = pos_x, pos_y

    self.density      = 0.0
    self.near_density = 0.0

    self.pressure      = 0.0
    self.near_pressure = 0.0

    self.neighbors = []

    self.x_force, self.y_force = 0.0, -gravity

    self.x_vel, self.y_vel = 0.0, 0.0`

const p2 = `class Particle(Particle):
  def update_state(self, gravity, max_velocity, velocity_reduction_factor, wall_rebound_factor, simulation_width, bottom_boundary):
    self.previous_pos_x, self.previous_pos_y = self.pos_x, self.pos_y

    self.x_vel += self.x_force
    self.y_vel += self.y_force

    self.pos_x += self.x_vel
    self.pos_y += self.y_vel

    self.x_force, self.y_force = 0.0, -gravity

    self.x_vel = self.pos_x - self.previous_pos_x
    self.y_vel = self.pos_y - self.previous_pos_y

    if math.sqrt(self.x_vel**2 + self.y_vel**2) > max_velocity:
      self.x_vel *= velocity_reduction_factor
      self.y_vel *= velocity_reduction_factor
    if self.pos_x < -simulation_width:
      self.x_force -= (self.pos_x - -simulation_width) * wall_rebound_factor
    if self.pos_x > simulation_width:
      self.x_force -= (self.pos_x - simulation_width) * wall_rebound_factor
    if self.pos_y < bottom_boundary:
      self.y_force -= (self.pos_y - simulation_width) * wall_rebound_factor

    self.density = 0.0
    self.near_density = 0.0
    self.neighbors = []`

const p3 = `class Particle(Particle):
  def calculate_pressure(self, pressure_constant, near_pressure_constant, default_density):
    self.pressure      = pressure_constant * (self.density - default_density)
    self.near_pressure = near_pressure_constant * self.near_density`

const ss1 = `class SimulationSpace:
  def __init__(self,
                particle_count,
                width,
                bottom,
                spacing,
                gravity,
                pressure_constant,
                near_pressure_constant,
                default_density,
                neighbor_radius,
                viscosity_constant,
                max_velocity,
                wall_rebound_factor,
                velocity_reduction_factor,
                max_frame):

    self.particle_count = particle_count
    self.width = width
    self.bottom = bottom
    self.spacing = spacing
    self.gravity = gravity
    self.pressure_constant = pressure_constant
    self.near_pressure_constant = near_pressure_constant
    self.default_density = default_density
    self.neighbor_radius = neighbor_radius
    self.viscosity_constant = viscosity_constant
    self.max_velocity = max_velocity
    self.wall_rebound_factor = wall_rebound_factor
    self.velocity_reduction_factor = velocity_reduction_factor
    self.max_frame = max_frame

    self.simulation_state = self.instantiate_particles(-width, -1, bottom, spacing, particle_count)

    self.frame = 0

    self.fig = matplotlib.pyplot.figure()
    self.axes = self.fig.add_subplot(111, xlim=(-width, width), ylim=(0, 1.5))
    self.POINTS = self.axes.scatter([], [], c=[], cmap='YlGnBu_r', s=10, vmin=0, vmax=1)
    self.ani = matplotlib.animation.FuncAnimation(self.fig, self.animate, frames=self.max_frame, interval=20, blit=True)`

const ss2 = `class SimulationSpace(SimulationSpace):
  def instantiate_particles(self, xmin, xmax, ymin, space, count):
    particles = []
    x_pos, y_pos = xmin, ymin
    for _ in range(count):
      particles.append(Particle(x_pos, y_pos, self.gravity))
      x_pos += space
      if x_pos > xmax:
        x_pos = xmin
        y_pos += space
    return particles`

const ss3 = `class SimulationSpace(SimulationSpace):
  def calculate_density(self, particles):
    for i, particle_1 in enumerate(particles):
      density = 0.0
      near_density = 0.0

      for particle_2 in particles[i + 1:]:
        distance = math.sqrt(
                  (particle_1.pos_x - particle_2.pos_x) ** 2
                  + (particle_1.pos_y - particle_2.pos_y) ** 2
                  )

        if distance < self.neighbor_radius:
          normal_distance = 1 - distance / self.neighbor_radius

          density += normal_distance**2
          near_density += normal_distance**3

          particle_2.density += normal_distance**2
          particle_2.near_density += normal_distance**3

          particle_1.neighbors.append(particle_2)

      particle_1.density += density
      particle_1.near_density += near_density`

const ss4 = `class SimulationSpace(SimulationSpace):
  def create_pressure(self, particles):
    for particle in particles:
      pressure_x = 0.0
      pressure_y = 0.0

      for neighbor in particle.neighbors:
        particle_to_neighbor = [
          neighbor.pos_x - particle.pos_x,
          neighbor.pos_y - particle.pos_y,
        ]

        distance = math.sqrt(particle_to_neighbor[0] ** 2 + particle_to_neighbor[1] ** 2)

        normal_distance = 1 - distance / self.neighbor_radius

        total_pressure = (
          particle.pressure + neighbor.pressure
        ) * normal_distance**2 + (
          particle.near_pressure + neighbor.near_pressure
        ) * normal_distance**3

        pressure_vector = [
          particle_to_neighbor[0] * total_pressure / (distance + 0.0000001),
          particle_to_neighbor[1] * total_pressure / (distance + 0.0000001),
        ]

        neighbor.x_force += pressure_vector[0]
        neighbor.y_force += pressure_vector[1]

        pressure_x += pressure_vector[0]
        pressure_y += pressure_vector[1]

      particle.x_force -= pressure_x
      particle.y_force -= pressure_y`

const ss5 = `class SimulationSpace(SimulationSpace):
  def calculate_viscosity(self, particles):
    for particle in particles:
      for neighbor in particle.neighbors:
          particle_to_neighbor = [
            neighbor.pos_x - particle.pos_x,
            neighbor.pos_y - particle.pos_y,
          ]

          distance = math.sqrt(particle_to_neighbor[0] ** 2 + particle_to_neighbor[1] ** 2)

          normal_p_to_n = [
            particle_to_neighbor[0] / (distance + 0.0000001),
            particle_to_neighbor[1] / (distance + 0.0000001),
          ]

          relative_distance = distance / self.neighbor_radius

          velocity_difference = (particle.x_vel - neighbor.x_vel) * normal_p_to_n[0] + (particle.y_vel - neighbor.y_vel) * normal_p_to_n[1]

          if velocity_difference > 0:
            viscosity_force = [
              (1 - relative_distance)
              * self.viscosity_constant
              * velocity_difference
              * normal_p_to_n[0],
              (1 - relative_distance)
              * self.viscosity_constant
              * velocity_difference
              * normal_p_to_n[1],
            ]

            particle.x_vel -= viscosity_force[0] * 0.5
            particle.y_vel -= viscosity_force[1] * 0.5
            neighbor.x_vel += viscosity_force[0] * 0.5
            neighbor.y_vel += viscosity_force[1] * 0.5`

const ss6 = `class SimulationSpace(SimulationSpace):
  def update(self):
    for particle in self.simulation_state:
        particle.update_state(self.gravity, self.max_velocity, self.velocity_reduction_factor, self.wall_rebound_factor, self.width, self.bottom)

    self.calculate_density(self.simulation_state)

    for particle in self.simulation_state:
        particle.calculate_pressure(self.pressure_constant, self.near_pressure_constant, self.default_density)

    self.create_pressure(self.simulation_state)
    self.calculate_viscosity(self.simulation_state)

    return self.simulation_state`

const ss7 = `class SimulationSpace(SimulationSpace):
  def animate(self, i):
    self.simulation_state = self.update()
    visual = numpy.array([[particle.pos_x, particle.pos_y] for particle in self.simulation_state])
    pressures = numpy.array([particle.pressure for particle in self.simulation_state])
    norm_pressures = (pressures - pressures.min()) / (pressures.max() - pressures.min())
    self.POINTS.set_offsets(visual)
    self.POINTS.set_array(norm_pressures)
    self.frame += 1
    return self.POINTS,

  def display_animation(self):
    return HTML(self.ani.to_jshtml())`

const run_sim = `sim_space = SimulationSpace(
    particle_count=1250,
    width=3,
    bottom=-0.05,
    spacing=0.05,
    gravity=0.02 * 0.25,
    pressure_constant=0.22 / 1000.0,
    near_pressure_constant=(0.22 / 1000.0) * 10,
    default_density=16,
    neighbor_radius=0.22 * 1.25,
    viscosity_constant=0.8,
    max_velocity=1,
    wall_rebound_factor=0.025,
    velocity_reduction_factor=0.00010,
    max_frame=500
)

sim_space.display_animation()`

const Fluids = () => {

  return (
    <div className='custom-font pt-9'>
        <Helmet>
          <title>Implementing Simple Smooth Particle Hydrodynamics Simulations from Scratch in Python</title>
          <meta name="author" content="Dinis Martinho" />
          <meta name="description" content="In this article, I will present a simple implementation of a smooth particle hydrodynamics (SPH) simulation written from scratch in 
        Python. I will provide both an explanation of how the mathematics work and the code necessary to implement them. Additionally, I will share the 
        resources that I've used to learn about this topic." />
        </Helmet>

        <Title Title="Implementing Simple Smooth Particle Hydrodynamics Simulations from Scratch in Python" date='28 Jun 2024' />
        <br />
        <ColabButton notebookUrl="https://colab.research.google.com/drive/17B43SvhRDWw-wWuUpoY1revWaXWvSJZy?usp=sharing" paperURL="" />
        <br />

        <SubTitle Title="Introduction" noMarginTop={true} />
        <br />
        <Paragraph text="In this article, I will present a simple implementation of a smooth particle hydrodynamics (SPH) simulation written from scratch in 
        Python. I will provide both an explanation of how the mathematics work and the code necessary to implement them. Additionally, I will share the 
        resources that I've used to learn about this topic." />
        <br />

        <div className='flex content-center justify-center'>
            <img className='sm:h-[400px]' src={process.env.PUBLIC_URL + "/Implementing-Simple-Smooth-Particle-Hydrodynamics-Simulations-from-Scratch-in-Python-I/sim_2.gif"} />
        </div>

        <br />
        <Paragraph text="Smooth Particle Hydrodynamics is a computational technique used for simulating fluid flows. It models fluids as a collection of particles, 
        making it highly versatile for a range of applications, from astrophysics to engineering. I've first encountered this technique while watching Sebastian Lague's 
        video, 'Coding Adventure: Simulating Fluids', where he shared his journey attempting to implement a three-dimensional fluid simulation in Unity. His results 
        inspired me to create my own version of a fluid simulation, albeit in two dimensions and using Python. The following implementation is my attempt at a simple 
        smooth particle hydrodynamics simulation. It's important to note that my implementation lacks features such as water tension, and I assumed that every 
        particle has the exact same mass in order to simplify the equations. Furthermore, it does not include several optimization features like GPU parallelization and hashed grids." />
        <br />
        <Paragraph text="Beyond the initial inspiration from Sebastian Lague's video, I found other valuable resources that accelerated my learning process on 
        this topic. Lucas V. Schuerman's blog post titled 'Implementing SPH in 2D' provided deeper insight into a more complex implementation of SPH using C++. 
        Additionally, AlexandreSajus's GitHub repository 'Python-Fluid-Simulation', where he explores a SPH implementation in Python, helped me translate the 
        mathematical formulas into code. Both the C++ and Python resources were instrumental in this translation. References to both of these resources can be 
        found in the references section of this blog post." />
        <br />
        
  
        <SubTitle Title="Understanding Smooth Particle Hydrodynamics" />
        <br />
        <Paragraph text="Smooth Particle Hydrodynamics is a technique used to simulate fluid flows. There are several types of SPH formulations, each tailored for specific scenarios 
        and computational efficiencies. Common variants include Weakly Compressible SPH (WCSPH), which is suitable for simulating incompressible fluids like water, and 
        Fully Compressible SPH (FCSPH), which handles compressible fluids found in aerodynamic simulations. Choosing the right formulation depends on the physical characteristics of the fluid and the 
        computational resources available." />
        <br />
        <Paragraph text="For this implementation, I opted for a basic smooth particle hydrodynamics approach. It lacks several features such as water tension and 
        assumes that every particle has the same mass to simplify the derivatives in the formulas. This SPH implementation can be broken down into three main simplified formulas responsible 
        for handling all the particle interactions:" />
        <br />
        <EquationRenderer equation='\rho_i = \sum_{j \neq i}  W(r_{ij}, h)' />
        <br />

        <Paragraph text="This formula calculates the density of each particle in the simulation based on its proximity to neighboring particles. The density 
        calculation is essential for simulating fluid behavior accurately because it influences the pressure and forces acting on each particle, which in turn 
        determine how the fluid behaves and moves. In this formula, `ρi` represents the density of particle `i`. The summation `Σ(j ≠ i)` is over all particles `j` that 
        are not equal to particle `i`, indicating that the density is influenced by the surrounding particles. The term `W(rij, h)` is a smoothing kernel function 
        that depends on the distance `rij` between particles `i` and `j` and the smoothing length `h`. The distance `rij` is the separation between the particles, and `h` is 
        the smoothing length, which determines the area of influence of each particle." />
        <br />
        <EquationRenderer equation='\mathbf{f}_{i} = \sum_{j \in \text{neighbors}} \left( \left( P_i + P_j \right) \left( 1 - \frac{r_{ij}}{h} \right)^2 + \left( \Pi_i + \Pi_j \right) \left( 1 - \frac{r_{ij}}{h} \right)^3 \right) \frac{\mathbf{r}_{ij}}{r_{ij} + \epsilon}' />
        <br />

        <Paragraph text="This formula calculates the pressure forces applied to particles within the simulation, taking into account their interactions with 
        neighboring particles. It iteratively computes pressure contributions scaled by the normalized distances between particles and adjusts these based on 
        their relative positions. In this context, `fi` is the force acting on particle `i`, and the summation `Σ(j in neighbors)` is over all neighboring particles `j`. 
        The pressures `Pi` and `Pj` represent the pressure at particles `i` and `j`, respectively. The term `(1 - rij / h)` is a normalized distance factor between particles 
        `i` and `j`, where `rij` is the distance between these particles and `h` is the smoothing length. The artificial viscosity terms `Πi` and `Πj` at particles `i` and `j`, 
        respectively, are included to handle numerical stability and dissipation effects. The position vector `rij` is from particle `i` to particle `j`, and `ε` is a small 
        value added to avoid division by zero." />
        <br />
        <EquationRenderer equation='\mathbf{f}_{\text{visc}} = - \left( 1 - \frac{r_{ij}}{h} \right) \cdot \nu \cdot \left( \mathbf{v}_{ij} \cdot \mathbf{r}_{ij} \right) \cdot \frac{\mathbf{r}_{ij}}{r_{ij}}' />
        <br />
        <Paragraph text="This formula models the viscous forces acting between particles in the simulation. It calculates these forces based on the relative 
        velocities `(vij)` of particle pairs, adjusted by their normalized distance `(1 - rij / h)`. Here, `f_visc` represents the viscous force acting between particles, 
        and `(1 - rij / h)` is a normalized distance factor between particles `i` and `j`. The term `ν` is the kinematic viscosity coefficient, which characterizes the fluid's 
        resistance to gradual deformation by shear or tensile stress. The relative velocity vector `vij` between particles `i` and `j` is adjusted by the dot 
        product `vij · rij`, representing the component of the velocity in the direction of the distance vector. The position vector `rij` from particle `i` to particle `j` 
        and the distance `rij` between these particles further influence the computation, contributing to the simulation's representation of viscous interactions among 
        neighboring particles." />
        <br />
        <Paragraph text="These three formulas form the foundation for ensuring that our simulation runs correctly. They may be different from the typical formulas found in 
        other implementations possibly because I assumed that each particle has uniform mass, allowing me to simplify certain calculations by omitting mass considerations."/>
        <br />

        <SubTitle Title="Implementation methodology" />
        <br />
        <Paragraph text="Following the approach of Alexandre Saju and Lucas V. Schuerman, I divided this implementation into two main classes. The `Particle` class 
        handles the internal logic of each particle, such as position updates and pressure calculations. The `SimulationSpace` class manages the interactions between 
        particles and the environment and handles the rendering of the scene using `matplotlib`. The code I wrote to render the scene will only work on Google Colab or 
        Jupyter notebooks." />
        <br />

        <SubTitle Title="Importing the necessary libraries" />
        <br />
        <CodeBlock code={imports} />
        <br />

        <SubTitle Title="Implementing the Particle class" />
        <br />
        <Paragraph text="As mentioned before, the `Particle` class is responsible for independent particle behaviors, such as updating position and velocities based on 
        pressure and density in each simulation frame. To achieve this, I initialized thirteen variables in the `__init__` function of the Particle class: `pos_x` and 
        `pos_y` for the current position; `previous_pos_x` and `previous_pos_y` for the previous position of the particle, used for calculating velocity; `density` and `near_density` for 
        affecting pressure calculations; `pressure` and `near_pressure` for influencing movement; `neighbors` to store neighboring particles; `x_force` and 
        `y_force` for the forces acting on the particle, initialized with gravity affecting the y-direction; and `x_vel` and `y_vel` for the velocities of the particle 
        in the x and y directions." />
        <br />
        <CodeBlock code={p1} />
        <br />
        <Paragraph text="The `update_state` function handles all independent particle movements and the boundary conditions of the simulation area every frame. The 
        velocity and positions are updated according to the forces stored in the particle. There is some logic to check whether the particle is within the simulation area; 
        if it is not, a rebound force is applied to the particle to push it back into the simulation area." />
        <br />
        <CodeBlock code={p2} />
        <br />
        <Paragraph text="This `calculate_pressure` function will be used in the main update loop of the simulation to calculate the pressure, taking into 
        consideration it's own density and the pressure of neighboring particles." />
        <br />
        <CodeBlock code={p3} />
        <br />

        <SubTitle Title="Implementing the SimulationSpace class" />
        <br />
        <Paragraph text="As mentioned before, the `SimulationSpace` class handles all group behaviors and interactions between particles, as well as the rendering of the scene 
        using `matplotlib`. The initialization of this class takes into consideration 14 different parameters." />
        <br />
        <Paragraph text="The parameters include `particle_count`, which determines the number of particles in the simulation, `width` and `bottom`, which define the 
        dimensions of the simulation area, `spacing` that sets the initial spacing between particles and `gravity` which applies a gravitational force to them. The 
        `pressure_constant` and `near_pressure_constant` are used for calculating the pressures affecting particle movement, while `default_density` sets the base density 
        of the fluid." />
        <br />
        <Paragraph text="To manage interactions, `neighbor_radius` defines the radius within which particles are considered neighbors, and `viscosity_constant` is 
        used for viscosity calculations. The `max_velocity` parameter limits the speed of the particles, ensuring stability. The `wall_rebound_factor` determines 
        how particles bounce off the simulation boundaries, and `velocity_reduction_factor` is used to gradually slow particles down if their velocity is superior to `max_velocity`. 
        Finally, `max_frame` specifies the total number of frames that the simulation will run." />
        <br />
        <CodeBlock code={ss1} />
        <br />
        <Paragraph text="The `instantiate_particles` function creates particles based on `self.particle_count` within a defined space, determined by the maximum x 
        position and minimum y position. This arrangement forms a tower of particles that can produce impressive wave effects when running the simulation." />
        <br />
        <CodeBlock code={ss2} />
        <br />

        <Paragraph text="Just as mentioned before, our `SimulationSpace` class needs to be able to calculate interactions between particles using the three formulas 
        described earlier. These formulas should be capable of considering individual particle characteristics and calculating the interactions between them. " />
        <br />
        <Paragraph text="The following function, `calculate_density`, is the code implementation of the equation below. Its job is to calculate the density of a 
        particle based on its proximity to neighboring particles. This function takes into account the distance between particles and applies a smoothing kernel 
        function to determine the influence of each neighbor on the particle's density. By summing these contributions, the function provides an accurate calculation 
        of the particle's density within the simulation space." />
        <br />
        <EquationRenderer equation='\rho_i = \sum_{j \neq i} W(r_{ij}, h)' />
        <br />
        <CodeBlock code={ss3} />
        <br />

        <Paragraph text="The `create_pressure` function is responsible for computing the pressure forces exerted on each particle within the simulation. It iterates through the list of particles and for each particle, it iterates through its neighboring particles. For each pair of particles, the function calculates the pressure contributions based on their respective pressures and distances. It employs a smoothing kernel approach to adjust these contributions, ensuring that closer neighbors exert stronger forces than those farther away. The resulting pressure forces are then applied to update the forces acting on each particle, influencing their movement and interaction dynamics within the simulation environment." />
        <br />
        <EquationRenderer equation='\mathbf{f}_{i} = \sum_{j \in \text{neighbors}} \left( \left( P_i + P_j \right) \left( 1 - \frac{r_{ij}}{h} \right)^2 + \left( \Pi_i + \Pi_j \right) \left( 1 - \frac{r_{ij}}{h} \right)^3 \right) \frac{\mathbf{r}_{ij}}{r_{ij} + \epsilon}' />
        <br />
        <CodeBlock code={ss4} />
        <br />

        <Paragraph text="This ``calculate_viscosity` function computes the viscous forces acting between particles in the simulation. It iterates through each particle and its neighboring particles to determine the relative velocities and distances between them, following the equation provided below. By considering the relative velocity and the normalized distance between particles, the function calculates the viscous forces that represent the resistance to motion within the fluid. The direction and magnitude of these forces are determined by the relative velocity vector and the distance vector between particles. This calculation is essential for accurately simulating viscous interactions, influencing how particles move and interact with each other in the simulated fluid environment." />
        <br />
        <EquationRenderer equation='\mathbf{f}_{\text{visc}} = - \left( 1 - \frac{r_{ij}}{h} \right) \cdot \nu \cdot \left( \mathbf{v}_{ij} \cdot \mathbf{r}_{ij} \right) \cdot \frac{\mathbf{r}_{ij}}{r_{ij}}' />
        <br />
        <CodeBlock code={ss5} />
        <br />
        <Paragraph text="With all the interactions between particles finally defined, we can implement the main simulation loop. The actual loop works by iterating 
        through each particle in the simulation state. For each particle, its state is updated based on parameters such as gravity, maximum velocity, 
        velocity reduction factor, wall rebound factor, and simulation boundaries. After updating the particle states, the simulation 
        calculates the density for each particle using the `calculate_density` method. Following this, each particle calculates its pressure based on constants 
        such as `pressure_constant`, `near_pressure_constant`, and `default_density`. Once the pressures are calculated, the `create_pressure` function computes the 
        pressure forces between neighboring particles. Finally, the `calculate_viscosity` method computes the viscous forces between particles. This iterative 
        process of updating particle states, calculating densities and pressures, and computing forces continues throughout the simulation loop, influencing 
        how particles move and interact within the simulated environment." />
        <br />  
        <CodeBlock code={ss6} />
        <br />
        <Paragraph text="We are now prepared to execute the simulation, though we still need to implement a visualization method. In the initialization function of 
        the simulation space, we established variables to facilitate animations using `matplotlib` plots. In the following function, we utilize the previously 
        defined `update` function, which computes the positions and pressures of all particles in the simulation. Each particle is visualized with a color 
        representing its pressure level. Please be aware that the plot display functionality is currently configured specifically for Google Colaboratory. 
        It may or may not function as expected on other platforms, necessitating potential code adjustments." />
        <br />
        <CodeBlock code={ss7} />
        <br />

        <SubTitle Title="Running the simulation" />
        <br />
        <CodeBlock code={run_sim} />

        <div className='flex content-center justify-center'>
            <img className='sm:h-[400px]' src={process.env.PUBLIC_URL + "/Implementing-Simple-Smooth-Particle-Hydrodynamics-Simulations-from-Scratch-in-Python-I/sim_1.gif"} />
        </div>

        <br />
        <SubTitle Title="Summative insights and future considerations" />
        <br />
        <Paragraph text="You are now capable of implementing a smoothed particle hydrodynamics (SPH) simulation in Python. Most of the concepts shown in here can be 
        easily translated into other programming languages and adapted for three-dimensional simulations instead of just two-dimensional ones. It's also important to note 
        that this implementation lacks several features, such as water tension, and optimization techniques like GPU acceleration." />
        <br />
        <Paragraph text="Personally, this small project taught me a lot about simulations and how they work, possibly opening new doors of possibilities for future projects and 
        intersections between simulations and machine learning." />
        <br />

        <SubTitle Title="Resources used" />
        <br />
        <Paragraph text="▸ [1] Lague, S. (2019, September 20). Coding Adventure: Simulating Fluids [Video]. YouTube. Retrieved June 25, 2024, from `https:// www.youtube.com/ watch?v= rSKMYc1CQHE&`" />
        <br />
        <Paragraph text="▸ [2] Schuermann, L. Implementing SPH in 2D. Lucas Schuermann. Retrieved June 26, 2024, from `https:// lucasschuermann.com/writing /implementing-sph-in-2d`" />
        <br />
        <Paragraph text="▸ [3] Sajus, A. Python Fluid Simulation. GitHub. Retrieved June 28, 2024, from `https:// github.com/AlexandreSajus /Python-Fluid-Simulation`" />
        <br />
        <Paragraph text="▸ [4] Pelfrey, B. Real-Time Physics 103: Fluid Simulation in Games. Brandon Pelfrey's Blog. Retrieved July 1, 2024, from `https: // web.archive.org/web / 20090722233436/ http: // blog.brandonpelfrey.com / ?p=303`" />
        <br />

        <DownSpace />
    </div>
  );
}
// {countdown}
export default Fluids;
