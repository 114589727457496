import React from 'react';
import DownSpace from '../components/DownSpace';
import { Helmet } from 'react-helmet';

const AboutMe = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className='bg-white border border-gray-200 lg:pt-11 lg:p-0 p-0 pt-11 rounded shadow-xl mt-9 custom-font relative overflow-hidden max-w-[440px] sm:max-w-auto w-[95%] sm:w-[60%] lg:w-[410px] md:w-[410px]'>

        <Helmet>
          <title>Dinis Martinho</title>
          <meta name="author" content="Dinis Martinho" />
        </Helmet>

        {/* Banner Image */}
        <div className='absolute top-0 left-0 w-full h-[130px] md:h-[145px] mb-0 border-b border-gray-300'>
          <img
            src={process.env.PUBLIC_URL + "/thumbnails/abstract_3.jpeg"}
            alt="Banner"
            className='w-full h-full object-cover'
          />
          {/* GitHub and LinkedIn Links */}
          <div className='absolute top-2 right-2 flex space-x-2'>
            <a
              href="https://github.com/DinisMartinho"
              target="_blank"
              rel="noopener noreferrer"
              className='hidden text-white tracking-wider font-bold  uppercase text-xs md:text-sm lg:text-sm transition duration-300 ease-in-out hover:text-gray-200'>
              GitHub
            </a>
            <a
              href="https://www.linkedin.com/in/dinismartinho"
              target="_blank"
              rel="noopener noreferrer"
              className='hidden text-white tracking-wider font-bold uppercase text-xs md:text-sm lg:text-sm transition duration-300 ease-in-out hover:text-gray-200'>
              LinkedIn
            </a>
          </div>
        </div>
        
        <div className='flex justify-center items-center lg:mb-1 md:mb-1 sm:mb-1 mb-1 relative z-11 sm:mt-6 mt-6 md:mt-10 lg:mt-10'>
          {/* Profile Image */}
          <div className='h-[6.05rem] w-[6.05rem] sm:h-[6.25rem] sm:w-[6.25rem] md:h-[6.25rem] md:w-[6.25rem] lg:w-[6.25rem] lg:h-[6.25rem] bg-white rounded-full overflow-hidden border-[4px] border-white relative z-10'>
            <img
              src={process.env.PUBLIC_URL + "/me.jpeg"}
              alt="Dinis Martinho"
              className='w-full h-full object-cover rounded-md'
            />
          </div>
        </div>

        <div className='text-container text-center'>
          {/* Introduction */}
          <h1 className='text-2xl md:text-3xl lg:text-3xl sm:text-2xl text-gray-900 mb-4 mt-2'>
            I'm Dinis Martinho
          </h1>
          <p className='text-sm md:text-base lg:text-base leading-relaxed px-8 text-justify text-gray-900'>
            Passionate about artificial intelligence, currently pursuing studies in Organizational Informatics and Communication at the University of Aveiro.
          </p>

          {/* Machine Learning Journey */}
          <h2 className='text-lg lg:text-xl md:text-xl sm:text-lg text-gray-800 mt-2 sm:mt-4 mb-1 sm:mb-2 hidden'>
            Machine Learning Journey
          </h2>
          <br />
          <p className='text-sm md:text-base lg:text-base leading-relaxed px-8 text-justify text-gray-800'>
            Explored machine learning with a focus on computer vision applications and engaged in continuous self-directed learning and hands-on projects.
          </p>

          {/* TeamZERO Experience */}
          <h2 className='text-lg lg:text-xl md:text-xl sm:text-lg text-gray-800 mt-2 sm:mt-4 mb-1 sm:mb-2 hidden'>
            TeamZERO Experience
          </h2>
          <br />
          <p className='text-sm md:text-base lg:text-[base] leading-relaxed px-8 text-justify text-gray-700'>
            Secured the fifth position in the MICCAI23 ARCADE competition as part of TeamZERO, contributing to Coronary Artery Disease diagnostics using machine learning and medical imaging.
          </p>

          {/* Line above GitHub and Email sections */}
          <hr className=" ml-8 mb-2 mr-8 border-orange-600 mt-5 hidden" />

          {/* GitHub Link */}
          <div className='flex ml-8 mt-8 mb-2 mr-8'>
            <a
              href="https://github.com/DinisMartinho"
              target="_blank"
              rel="noopener noreferrer"
              className='pb-4 text-orange-600 hover:text-orange-600 hover:underline underline-orange-600 transition duration-300 ease-in-out flex font-bold text-sm md:text-base lg:text-base'>
              <span className="font-bold text-sm md:text-sm lg:text-[lg] tracking-widest">GITHUB</span>
            </a>
            <a
              href="mailto:dinismartinho@ua.pt"
              target="_blank"
              rel="noopener noreferrer"
              className='pb-4 ml-4 text-orange-600 hover:text-orange-600 hover:underline underline-orange-600 transition duration-300 ease-in-out flex font-semibold text-sm md:text-base lg:text-base'>
              <span className="font-bold text-sm md:text-sm lg:text-[lg] tracking-widest">EMAIL</span>
            </a>
            <a
              href="https://arxiv.org/search/?query=Dinis+Martinho&searchtype=all&source=header"
              target="_blank"
              rel="noopener noreferrer"
              className='hidden pl-max pb-4 ml-4 text-orange-600 hover:text-orange-600 hover:underline underline-orange-600 transition duration-300 ease-in-out flex font-semibold text-sm md:text-base lg:text-base'>
              <span className="font-extrabold text-sm tracking-widest">ARXIV</span>
            </a>

            <h1 className='ml-auto text-sm md:text-sm lg:text-[md] tracking-wider font- text-gray-600'>MAY 13, 2024</h1>
          </div>
        </div>
      </div>
      <br />
      <br />
      
      </div>
  );
}

export default AboutMe;
