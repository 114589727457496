import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

const DALLE1 = () => {
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    // Set the date of May 5th, 2024
    const targetDate = new Date('2024-05-19');
    
    // Update countdown every second
    const intervalId = setInterval(() => {
      const now = new Date();
      const difference = targetDate - now;
      
      // Calculate days, hours, minutes, and seconds
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      
      // Format the countdown string
      const countdownString = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      
      // Update the state with the countdown string
      setCountdown(countdownString);
      
      // Clear interval when countdown reaches zero
      if (difference <= 0) {
        clearInterval(intervalId);
        setCountdown('Countdown Over!');
      }
    }, 1000);
    
    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='custom-font mt-24' style={{ textAlign: 'center', fontSize: '1.25em', fontWeight: 'bold' }}>
        <Helmet>
          <title>Transformer-Powered Non-Conditional Latent Image Generation from Scratch within PyTorch</title>
          <meta name="author" content="Dinis Martinho" />
        </Helmet>
        
        <div className='mt-32 text-gray-600'>
             Transformer-Powered Non-Conditional Latent Image Generation from Scratch within PyTorch
        </div>
        
        <div className='mt-4 text-gray-600'>
        </div>
    </div>
  );
}
// {countdown}
export default DALLE1;
