import React from 'react';

const DownSpace = () => {
  return (
    <div className='pt-36 content-center custom-font'>
      <div className='flex content-center text-center justify-center'>
        <p className='pr-2 text-[#ff4d04]'>&copy;</p>
        <p className='pb-9 content-center justify-center text-center'>{new Date().getFullYear()} Dinis Martinho</p>
      </div>
    </div>
  );
};

export default DownSpace;
