import React from 'react'

const Title = ({ Title, date = "30 Apr 2024" }) => {
  return (
    <div>
      <h1 className='custom-font pt-0 text-xl md:text-2xl lg:text-2xl'>
        <span className='font-extrabold'># </span> <span className='font-extrabold'>{Title}</span>
        <span className='align-middle tracking-wider text-[14px] md:text-[16px] lg:text-[16px] text-gray-700 ml-4 bg-gray-100'>

           <span className='align-middle tracking-wider text-[7px] md:text-[9px] mr-2 text-gray-600'>●</span>

          {date}

        </span>

      </h1>
    </div>
  )
}

export default Title