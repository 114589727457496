import React from 'react';

const Paragraph = ({ text }) => {
  // Use regular expression to match words within backticks and phrases within double question marks
  const wordsAndPhrases = text.match(/`([^`]+)`|\?\?.*?\?\?|\S+/g);

  return (
    <div className='text-justify text-[14px] md:text-[16px]'>
      {wordsAndPhrases.map((wordOrPhrase, index) => {
        const isSurroundedByBackticks = /^`.*`$/.test(wordOrPhrase);
        const isSurroundedByDoubleQuestionMarks = /^\?\?.*?\?\?$/.test(wordOrPhrase);

        let contentWithoutFormatting = wordOrPhrase;

        // Remove backticks and double question marks if present
        if (isSurroundedByBackticks) {
          contentWithoutFormatting = contentWithoutFormatting.replace(/`/g, '');
        }
        if (isSurroundedByDoubleQuestionMarks) {
          contentWithoutFormatting = contentWithoutFormatting.replace(/\?\?/g, '');
        }

        // Check if the current word or phrase is surrounded by backticks or double question marks and apply styles accordingly
        return (
          <React.Fragment key={index}> 
            {/* Add space only if not the first word/phrase and the current word/phrase is not a dot */}
            {index > 0 && wordOrPhrase !== '.' && ' '}
            <span style={{ 
              //backgroundColor: isSurroundedByBackticks ? 'antiquewhite' : (isSurroundedByDoubleQuestionMarks ? '#cce6ff' : 'transparent'), 
              //fontWeight: isSurroundedByBackticks || isSurroundedByDoubleQuestionMarks ? 'bolder' : 'normal',
              //color: isSurroundedByBackticks ? '#ff4d04' : (isSurroundedByDoubleQuestionMarks ? '#5b7a99' : 'black'),

              backgroundColor: isSurroundedByBackticks ? 'antiquewhite' : (isSurroundedByDoubleQuestionMarks ? '#cce6ff' : 'transparent'), 
              fontWeight: isSurroundedByBackticks || isSurroundedByDoubleQuestionMarks ? 'bolder' : 'normal',
              color: isSurroundedByBackticks ? '#ff4d04' : (isSurroundedByDoubleQuestionMarks ? '#35516e' : 'black'),
            }}>
              {contentWithoutFormatting}
            </span>
            {/* Add space only if not the last word/phrase, the current word/phrase does not end with a dot,
            the next word/phrase is not highlighted, and the next word/phrase is not a comma */}
            {index < wordsAndPhrases.length - 1 && !wordOrPhrase.endsWith('.') && !/^(?:`.*`|\?\?.*?\?\?)$/.test(wordsAndPhrases[index + 1]) && wordsAndPhrases[index + 1] !== ',' && ' '}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Paragraph;
