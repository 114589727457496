import React, { useEffect } from 'react';

const EquationRenderer = ({ equation }) => {
  useEffect(() => {
    // Load MathJax dynamically
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_HTMLorMML';
    script.async = true;

    const renderEquation = () => {
      // Check if MathJax is loaded
      if (window.MathJax) {
        window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
      }
    };

    script.onload = renderEquation;

    // Append the script to the document
    document.head.appendChild(script);

    // Clean up function to remove the script when component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, [equation]);

  return (
    <div className='text-center'>
      {/* Render the equation using LaTeX syntax */}
      <div className='overflow-x-auto overflow-y-hidden pb-0 px-3'>
        <span className='text-justify text-[14px] md:text-[17px]'>{`\\(${equation}\\)`}</span>
      </div>
    </div>
  );
};

export default EquationRenderer;
